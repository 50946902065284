import { Icon, Tooltip } from "@chakra-ui/react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box } from "@mui/material";
import React from "react";
import {
  useFinalizeSubmissionMutation,
  useUnfinalizeSubmissionMutation,
} from "src/services/submission.service";

export const FinalizeSubmission = ({
  submissionId,
  finalized,
  unfinalizePermission,
}: {
  submissionId: string;
  finalized: boolean;
  unfinalizePermission: boolean;
}) => {
  const [internalFinalized, setInternalFinalized] = React.useState(finalized);
  const [finalizeSubmission] = useFinalizeSubmissionMutation();
  const [unfinalizeSubmission] = useUnfinalizeSubmissionMutation();

  React.useEffect(() => {
    setInternalFinalized(finalized);
  }, [finalized]);

  const handleFinalize = async () => {
    if (!unfinalizePermission) return;
    finalizeSubmission({ submissionId });
    setInternalFinalized(true);
  };

  const handleUnfinalize = async () => {
    if (!unfinalizePermission) return;
    unfinalizeSubmission({ submissionId });
    setInternalFinalized(false);
  };

  return (
    <Box
      data-test-id={internalFinalized ? "unfinalize-icon" : "finalize-icon"}
      sx={{ display: "flex", width: "100%" }}
    >
      {internalFinalized ? (
        <Tooltip
          label={
            unfinalizePermission
              ? "Click to unfinalize."
              : "Submission has been finalized."
          }
        >
          <Icon
            onClick={handleUnfinalize}
            as={LockOutlinedIcon}
            boxSize={6}
            color="white"
          />
        </Tooltip>
      ) : (
        <Tooltip
          label={
            unfinalizePermission
              ? "Click to finalize submission."
              : "Submission is currently unfinalized"
          }
        >
          <span
            onClick={handleFinalize}
            className="material-symbols-outlined cursor-pointer"
          >
            lock_open_right
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
