import React from "react";
import { AudienceSelection } from "./AudienceSelection";
import { useAppDispatch, useAppSelector } from "src/store";
import { FormElementBuildViewProps, RichTextDelta } from "src/types/form.types";
import { updateFormElement } from "src/store/slices/form.slice";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { selectSelectedElement } from "src/store/selectors/selected-element.selectors";
import { TextBlockPreview } from "./TextBlockPreview";
import { RichTextEditor } from "./RichTextEditor";

export const TextBlockBuildView = ({ id }: FormElementBuildViewProps) => {
  const selectedElementId = useAppSelector(selectSelectedElement);
  const element = useAppSelector(lookupFormElementById(id));
  const dispatch = useAppDispatch();
  const dataTestIdPathIndex = React.useMemo(
    () => element?.pathIndex.join("-"),
    [element?.pathIndex]
  );

  if (element && element.type !== "textBlock") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const handleTextValueChange = React.useCallback(
    (value: RichTextDelta) => {
      if (element) {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              value: { ops: value.ops },
            },
          })
        );
      }
    },
    [dispatch, element]
  );

  const handleAudienceChange = React.useCallback(
    (audience: string) => {
      if (element) {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              audience,
            },
          })
        );
      }
    },
    [dispatch, element]
  );

  if (!element) {
    return null;
  }

  return selectedElementId === id ? (
    <div className="my-text-block">
      <div
        style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
      >
        <AudienceSelection
          audience={element.content.audience}
          changeAudience={handleAudienceChange}
          staticContent={true}
        />
      </div>
      <RichTextEditor
        dataTestId={`text-block-${dataTestIdPathIndex}`}
        value={element.content.value}
        onChange={handleTextValueChange}
      />
    </div>
  ) : (
    <TextBlockPreview id={id} />
  );
};
