import { useAPIRequest } from "../hooks/useAPI";

export const useJobAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const postJob = async ({
    fileId,
    testParams,
  }: {
    fileId: string;
    testParams: any;
  }) => {
    const response = await sendAPIRequest(`jobs`, "POST", {
      fileId,
      testParams,
    });
    return response.data;
  };

  const getJobById = async (jobId: string) => {
    const response = await sendAPIRequest(`jobs/${jobId}`);
    return response.data;
  };

  return {
    postJob,
    getJobById,
  };
};
