import { Text, Icon, HStack, Divider, Checkbox } from "@chakra-ui/react";
import SendOutlined from "@mui/icons-material/SendOutlined";
import { useState } from "react";
import { Collapse } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "./Spacer";
import _ from "lodash";
import { SlateObject } from "../types";
import RichText, { richTextInitialValue } from "src/components/RichText";

export const NewCommentInput = ({
  placeholderText = "Add a comment",
  onSend,
}: {
  placeholderText?: string;
  onSend: (
    comment: SlateObject,
    assignedTo?: string[],
    mentioned?: string[]
  ) => boolean | void | Promise<void>;
}) => {
  const [displayAssignmentOption, setDisplayAssignmentOption] =
    useState<boolean>(false);
  const [assign, setAssign] = useState<boolean>(false);
  const [mentionedUser, setMentionedUser] = useState<string[]>([]);
  const [text, setText] = useState<SlateObject>(richTextInitialValue);
  const [status, setStatus] = useState<string>("init");

  const changeText = (value: SlateObject) => {
    setText(value);

    const textNodes = value[0].children;

    const mentionedUsers = textNodes.filter(
      (textNode: any) => textNode.type == "@"
    );

    if (mentionedUsers.length > 0) {
      setDisplayAssignmentOption(true);
      setMentionedUser(mentionedUsers.map((textNode: any) => textNode.value));
    } else {
      setDisplayAssignmentOption(false);
    }
  };

  const onClick = async () => {
    if (_.isEqual(text, richTextInitialValue)) return;
    setStatus("sending");

    if (assign) {
      await onSend(text, mentionedUser, []);
    } else if (mentionedUser && !assign) {
      await onSend(text, [], mentionedUser);
    } else {
      await onSend(text, [], []);
    }

    setText({});
    setMentionedUser([]);
    setDisplayAssignmentOption(false);

    setStatus("init");
  };

  return (
    <>
      <HStack
        width={"100%"}
        alignContent={"flex-start"}
        justifyItems={"space-between"}
      >
        <div style={{ width: "85%" }}>
          {status === "sending" ? (
            <Text>Sending</Text>
          ) : (
            <RichText
              dataTestId="new-convo-input"
              includeMentions={true}
              value={text}
              onChange={(value) => changeText(value)}
              size={"fit"}
              placeholder={placeholderText}
              showToolbar={false}
              borderColor="border-none"
            />
          )}
        </div>
        <Icon
          data-test-id="comment-input-send-btn"
          cursor={_.isEqual(text, richTextInitialValue) ? "default" : "pointer"}
          onClick={onClick}
          color={
            _.isEqual(text, richTextInitialValue)
              ? "gray.200"
              : "brightblue.500"
          }
          boxSize={5}
          as={SendOutlined}
        />
      </HStack>
      <Divider borderWidth="1px" borderColor={"gray.800"} />
      <Collapse in={displayAssignmentOption}>
        <Spacer y={SpacerSizes.sm} />
        <Checkbox
          data-test-id="assign-comment-checkbox"
          colorScheme="brightblue"
          isChecked={assign}
          onChange={() => setAssign(!assign)}
        >
          <Text variant="caption">
            Assign to {mentionedUser.join(", ")}. They will be notified and
            responsible for marking as done.
          </Text>
        </Checkbox>
      </Collapse>
    </>
  );
};
