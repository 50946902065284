import React from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectErrorText } from "src/store/selectors/snackbar-text.selectors";
import { ErrorSnackbar } from "./ErrorSnackbar";
import { setErrorText } from "src/store/slices/snackbar-text.slice";

export const StateErrorSnackbar = () => {
  const errorText = useAppSelector(selectErrorText);
  const dispatch = useAppDispatch();

  const handleSnackbarClose = React.useCallback(() => {
    dispatch(setErrorText(null));
  }, [dispatch]);

  return <ErrorSnackbar message={errorText} onClose={handleSnackbarClose} />;
};
