import React from "react";
import {
  Text,
  Tooltip,
  Icon,
  HStack,
  Switch,
  Flex,
  VStack,
  Input,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AdvancedSectionWrapper } from "./AdvancedSectionWrapper";
import { AdvancedNotes } from "./AdvancedNotes";
import { useAppDispatch, useAppSelector } from "src/store";
import { FormElementBuildViewProps, RichTextDelta } from "src/types/form.types";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { selectSelectedElement } from "src/store/selectors/selected-element.selectors";
import { updateFormElement } from "src/store/slices/form.slice";
import { SingleSelectPreview } from "./SingleSelectPreview";
import { BuildViewHeader } from "./BuildViewHeader";
import { RichTextEditor } from "./RichTextEditor";

export const SingleSelectBuildView = ({ id }: FormElementBuildViewProps) => {
  const selectedElementId = useAppSelector(selectSelectedElement);
  const element = useAppSelector(lookupFormElementById(id));
  const dataTestIdPathIndex = React.useMemo(
    () => element?.pathIndex.join("-"),
    [element?.pathIndex]
  );
  const dispatch = useAppDispatch();

  if (element && element.type !== "singleSelect") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const handleHeaderChange = React.useCallback(
    (field: "title" | "audience") => {
      return (value: string) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: value,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleRichTextChange = React.useCallback(
    (field: "surveyTakerNotes" | "adjudicatorNotes" | "question") => {
      return (value: RichTextDelta) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: { ops: value.ops },
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleOptionLabelChange = React.useCallback(
    (optionIndex: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        if (element) {
          const updatedItems = element.content.items.map((item, i) => {
            return optionIndex === i
              ? { ...item, label: e.target.value }
              : item;
          });

          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                items: updatedItems,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleDeleteItem = React.useCallback(
    (optionIndex: number) => {
      return () => {
        if (element) {
          const updatedItems = element.content.items.filter(
            (_, i) => i !== optionIndex
          );
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                items: updatedItems,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleAddItem = React.useCallback(() => {
    if (element) {
      const updatedItems = [
        ...element.content.items,
        {
          label: `Option ${element.content.items.length + 1}`,
          selected: false,
          allowed: true,
        },
      ];

      dispatch(
        updateFormElement({
          ...element,
          content: {
            ...element.content,
            items: updatedItems,
          },
        })
      );
    }
  }, [dispatch, element]);

  const handleOptionConfigChange = React.useCallback(
    (field: "other" | "none" | "all") => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: {
                  label: field.charAt(0).toUpperCase() + field.slice(1),
                  allowed: e.target.checked,
                },
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const isSelectedElement = selectedElementId === id;

  if (!element) {
    return null;
  }

  return isSelectedElement ? (
    <div>
      <Flex w={"100%"} alignItems="center" direction="column">
        <div style={{ width: "100%" }}>
          <BuildViewHeader
            id={id}
            titleDataTestId={`single-select-title-input-${dataTestIdPathIndex}`}
            rules={element?.rules ?? []}
            questionPrompt="Question"
            title={element.content.title}
            titlePlaceholder="Untitled Single Selection Question"
            titleNumber={element?.titleNumber ?? ""}
            audience={element.content.audience}
            onAudienceChange={handleHeaderChange("audience")}
            onTitleChange={handleHeaderChange("title")}
          />
          <RichTextEditor
            placeholder="Enter the question"
            value={element.content.question}
            onChange={handleRichTextChange("question")}
          />
        </div>
        <Spacer y={SpacerSizes.sm} />

        <HStack
          w={"100%"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <VStack alignItems={"flex-start"} w={"47%"}>
            <Text>Single Select Response Options</Text>
            <Text style={{ marginLeft: 27 }}>Label</Text>
            {element.content.items.map((item, i) => (
              <HStack w={"100%"} key={i} spacing={0}>
                <div style={{ width: 25 }}>
                  <Text>{i + 1}.</Text>
                </div>

                <Input
                  size="sm"
                  onChange={handleOptionLabelChange(i)}
                  borderColor={"gray.300"}
                  value={item.label}
                />
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={handleDeleteItem(i)}
                  as={DeleteOutlineIcon}
                />
              </HStack>
            ))}
            <Icon
              style={{ cursor: "pointer" }}
              onClick={handleAddItem}
              boxSize={5}
              as={MdOutlineAddCircleOutline}
            />
          </VStack>

          <VStack alignItems={"flex-start"} w={"49%"}>
            <Text>Special Responses</Text>
            <Spacer y={SpacerSizes.md} />
            <HStack
              w={"100%"}
              contentEditable={false}
              alignContent="center"
              h={"35px"}
            >
              <Switch
                contentEditable={false}
                size="sm"
                isChecked={element.content.other.allowed}
                colorScheme={"brightblue"}
                onChange={handleOptionConfigChange("other")}
              />
              <Text>Other</Text>
              <Tooltip
                label={
                  "When enabled, the end user may provide up to one additional option selection that they specify."
                }
                fontSize="xs"
              >
                <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
              </Tooltip>
            </HStack>

            <HStack
              w={"100%"}
              contentEditable={false}
              alignContent="center"
              h={"35px"}
            >
              <Switch
                contentEditable={false}
                size="sm"
                isChecked={element.content.none.allowed}
                colorScheme={"brightblue"}
                onChange={handleOptionConfigChange("none")}
              />
              <Text>None</Text>
              <Tooltip
                label={
                  "When enabled, the end user may select 'None of the above' as an option."
                }
                fontSize="xs"
              >
                <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
              </Tooltip>
            </HStack>
          </VStack>
        </HStack>
      </Flex>
      <AdvancedSectionWrapper
        dataTestId={`advanced-section-${dataTestIdPathIndex}`}
      >
        <div style={{ width: "100%" }}>
          <AdvancedNotes
            dataTestIdPathIndex={dataTestIdPathIndex}
            updateReviewerNotes={handleRichTextChange("adjudicatorNotes")}
            updateSubmitterNotes={handleRichTextChange("surveyTakerNotes")}
            surveyTakerNotes={element.content.surveyTakerNotes}
            adjudicatorNotes={element.content.adjudicatorNotes}
          />
        </div>
      </AdvancedSectionWrapper>
    </div>
  ) : (
    <SingleSelectPreview id={id} />
  );
};
