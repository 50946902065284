import { OrganizationMembership } from "src/types/organization.types";
import {
  MentionableUser,
  OrganizationPrivilegeSettings,
  MembershipDetailUpdate,
  DataResponse,
} from "../types";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  getAuthenticatedBaseQuery,
  transformDataResponse,
} from "src/util/base-query.utils";

export const organizationApi = createApi({
  reducerPath: "@organizationApi",
  baseQuery: getAuthenticatedBaseQuery(`${process.env.REACT_APP_API_URL}`),
  endpoints: (builder) => ({
    getOrganizationMembers: builder.query<
      MentionableUser[],
      { targetOrganizationId: string | null }
    >({
      query: () => ({
        url: "/organization/membershipsDetail",
      }),
      transformResponse: (response: DataResponse<OrganizationMembership[]>) => {
        return response.data.map(
          (organizationMembership: OrganizationMembership) => {
            return {
              key: organizationMembership.userId!,
              text:
                organizationMembership.user?.firstName &&
                organizationMembership.user?.lastName
                  ? `${organizationMembership.user.firstName} ${organizationMembership.user.lastName}`
                  : organizationMembership.user?.email
                    ? organizationMembership.user.email
                    : "",
              data: {
                email: organizationMembership.user?.email ?? "",
              },
            };
          }
        );
      },
    }),

    updateOrganization: builder.mutation<
      { message: string },
      { name: string; description: string }
    >({
      query: (body) => ({
        url: "/organization",
        method: "PATCH",
        body,
      }),
      transformResponse: transformDataResponse<{ message: string }>,
    }),

    updateOrganizationPrivilegeSettings: builder.mutation<
      { message: string },
      OrganizationPrivilegeSettings
    >({
      query: (body) => ({
        url: "/organization/privilegeSettings",
        method: "PATCH",
        body,
      }),
      transformResponse: transformDataResponse<{
        message: string;
      }>,
    }),

    inviteUserToOrganization: builder.mutation<
      { message: string; userId: string },
      { userInfo: MembershipDetailUpdate }
    >({
      query: (body) => ({
        url: "/organization/invite-user",
        method: "PUT",
        body,
      }),
      transformResponse: transformDataResponse<{
        message: string;
        userId: string;
      }>,
    }),

    updateOrganizationMembership: builder.mutation<
      { message: string },
      { userInfo: MembershipDetailUpdate }
    >({
      query: (body) => ({
        url: "/organization/update-membership",
        method: "PUT",
        body,
      }),
      transformResponse: transformDataResponse<{ message: string }>,
    }),

    updateOrganizationAllowedDomains: builder.mutation<
      { message: string },
      string[]
    >({
      query: (allowedDomains) => ({
        url: "/organization/allowedDomains",
        method: "PATCH",
        body: { allowedDomains },
      }),
      transformResponse: transformDataResponse<{ message: string }>,
    }),
  }),
});

export const {
  useGetOrganizationMembersQuery,
  useLazyGetOrganizationMembersQuery,
  useUpdateOrganizationMutation,
  useUpdateOrganizationAllowedDomainsMutation,
  useUpdateOrganizationPrivilegeSettingsMutation,
  useInviteUserToOrganizationMutation,
  useUpdateOrganizationMembershipMutation,
} = organizationApi;
