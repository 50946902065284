import React from "react";
import {
  Center,
  Heading,
  Flex,
  Card,
  CardBody,
  Spacer as FlexSpacer,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { Colors } from "../theme/colors";
import { SubmissionsSection } from "../components/SubmissionSection";
import { EmptySubmissionsSection } from "../components/EmptySubmissionsSection";
import { LoadingSubmissions } from "../components/LoadingSubmissions";
import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { useLazyGetSubmissionsQuery } from "src/services/submission.service";
import { EnrichedSubmissionContentless } from "src/types";
import { Header } from "src/lib/components/Header";

export const SubmissionsPage = () => {
  const [submissionsList, setSubmissionsList] = React.useState<
    EnrichedSubmissionContentless[]
  >([]);
  const [finalizedSubmissionsList, setFinalizedSubmissionsList] =
    React.useState<EnrichedSubmissionContentless[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [getSubmissions] = useLazyGetSubmissionsQuery();

  const refreshSubmissions = React.useCallback(async () => {
    const submissions = await getSubmissions().unwrap();
    setSubmissionsList(submissions);
    return;
  }, [getSubmissions]);

  const refreshFinalizedSubmissions = React.useCallback(async () => {
    await getSubmissions({ finalized: true })
      .unwrap()
      .then((data) => {
        setFinalizedSubmissionsList(data);
      })
      .catch((err) => console.log(err));
    return;
  }, [getSubmissions]);

  const refreshAllSubmissions = React.useCallback(async () => {
    await refreshSubmissions();
    await refreshFinalizedSubmissions();
    setLoading(false);
  }, [refreshSubmissions, refreshFinalizedSubmissions]);

  React.useEffect(() => {
    refreshAllSubmissions();
    // we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticatedPage title="Submissions">
      <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
        <Flex>
          <Center mt="12px" mb="12px">
            <Header data-test-id="submissions-page-header-text">
              Manage Submissions
            </Header>
          </Center>
          <FlexSpacer />
        </Flex>
        <Spacer y={SpacerSizes.sm} />
        <Tabs>
          <TabList>
            <Tab data-test-id="submissions-in-progress-tab">In Progress</Tab>
            <Tab data-test-id="submissions-finalized-tab">Finalized</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {loading ? (
                <LoadingSubmissions />
              ) : submissionsList.length ? (
                <SubmissionsSection
                  tableDataTestId="in-progress-submissions-table"
                  submissions={submissionsList}
                  refreshSubmissions={refreshAllSubmissions}
                  finalizePanel={false}
                />
              ) : (
                <EmptySubmissionsSection />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <LoadingSubmissions />
              ) : finalizedSubmissionsList.length ? (
                <SubmissionsSection
                  tableDataTestId="finalized-submissions-table"
                  submissions={finalizedSubmissionsList}
                  refreshSubmissions={refreshAllSubmissions}
                  finalizePanel={true}
                />
              ) : (
                <Card style={{ cursor: "pointer" }}>
                  <CardBody
                    style={{
                      backgroundColor: "white",
                      border: "dashed",
                      borderWidth: "1px",
                      borderColor: Colors.brand[500],
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Heading variant="h2" color="brand.500" mt="6px" mb="6px">
                      You have no finalized Submissions.
                    </Heading>
                    <Spacer x={SpacerSizes.md} />
                  </CardBody>
                </Card>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Spacer y={SpacerSizes.xl} />
      </div>
    </AuthenticatedPage>
  );
};
