export interface ErrorResponse<Error> {
  data: {
    error: Error;
  };
}

export interface ErrorResponseData {
  code: string;
  message: string;
  status: string;
}

export const isErrorResponse = (
  response: unknown
): response is ErrorResponse<ErrorResponseData> => {
  return (
    typeof response === "object" &&
    response !== null &&
    "data" in response &&
    response.data !== null &&
    typeof response.data === "object" &&
    "error" in response.data &&
    isErrorResponseData(response.data.error)
  );
};

export const isErrorResponseData = (
  error: unknown
): error is ErrorResponseData => {
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    typeof error.code === "string"
  );
};
