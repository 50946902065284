import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { OrganizationMembershipModal } from "./Modal/OrganizationMembershipModal";
import { MembershipDetail } from "../types";

import MembersTableBody from "./MembersTableBody";
import { useOrganizationAPI } from "../endpoints/organization";
import { useAppSelector } from "../store";

const thStyle = {
  height: "140px",
  whiteSpace: "nowrap",
  verticalAlign: "bottom",
};
const spanStyle = {
  padding: "5px 10px",
  fontSize: "11.0pt",
  color: "var(--chakra-colors-gray-600)",
};

const spanStyleNarrow = {
  padding: "5px 10px",
  fontSize: "11.0pt",
  color: "var(--chakra-colors-gray-600)",

  width: "140px",
};

const rotationStyle = {
  transform: "translate(4px, -4px) rotate(315deg)",
  width: "20px",
  overflow: "visible",
};

export default function MembersTabPanel() {
  const { userContext } = useAppSelector((state) => state.user);
  const [membershipData, setMembershipData] = useState<MembershipDetail[]>();
  const [membershipToEdit, setMembershipToEdit] = useState<MembershipDetail>();
  const { getOrganizationMembers } = useOrganizationAPI();

  const getMembers = React.useCallback(async () => {
    const qr: MembershipDetail[] = await getOrganizationMembers();
    setMembershipData(qr.filter((member) => member.user));
  }, [setMembershipData, getOrganizationMembers]);

  const {
    isOpen: addUserModalOpen,
    onOpen: openAddUserModal,
    onClose: closeAddUserModal,
  } = useDisclosure();

  /**
   * @todo: RTK Candidate
   * We should consider refactoring this
   */
  useEffect(() => {
    getMembers();
  }, [getMembers]);

  return (
    <>
      {userContext?.currentOrgMembership?.organization?.name ? (
        <OrganizationMembershipModal
          isOpen={addUserModalOpen}
          onClose={() => {
            closeAddUserModal();
            getMembers();
          }}
          membershipToEdit={membershipToEdit as MembershipDetail}
        />
      ) : null}
      <table data-test-id="org-members-table" style={{ width: "1200px" }}>
        <thead>
          <tr>
            <th></th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Name</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Email</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Relationship</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Accepted</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Expiration</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage All Permissions</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage Org Settings</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Invite Users</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Create or Copy Forms</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage Legal Privilege</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>See All Members</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>See All Guests</span>
              </div>
            </th>
          </tr>
        </thead>
        {membershipData ? (
          <MembersTableBody
            membershipData={membershipData}
            setMembershipToEdit={setMembershipToEdit}
            openAddUserModal={openAddUserModal}
          />
        ) : null}
        <tfoot></tfoot>
      </table>
    </>
  );
}
