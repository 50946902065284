import { useAPIRequest } from "../hooks/useAPI";
import React from "react";

export const useUserAPI = () => {
  const sendAPIRequest = useAPIRequest();

  const getUserById = React.useCallback(
    async (userId: string) => {
      const response = await sendAPIRequest(`user/${userId}`, "GET");
      return response.data;
    },
    [sendAPIRequest]
  );

  return { getUserById };
};
