import {
  Icon,
  Table,
  Text,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { now } from "moment";

import {
  FormsTableMenuList,
  FormsTableMenuListProps,
  tooltipOpenDelay,
} from "./FormsTableMenuList";

interface FormDataRow {
  title: string;
  description: string;
  updatedAt: Date | null;
}

type FormsTableProps = Omit<
  FormsTableMenuListProps,
  "index" | "hoverRowIndex" | "onOpenInFormBuilder"
> & {
  dataTestId?: string;
  headers: string[];
  rowsData: FormDataRow[];
  rowClickHandlers: Array<() => void>;
};

export const FormsTable = ({
  dataTestId,
  headers,
  rowsData,
  rowCallbacks,
  rowsAllowedActions,
  rowsAttentionItems,
  rowClickHandlers,
  archivePanel,
}: FormsTableProps) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [hoverRowIndex, setHoverRowIndex] = useState<number | null>(null);

  const handleRowClick = (index: number) => {
    if (selectedRowIndex !== index) {
      setSelectedRowIndex(index);
    }
  };

  const handleRowMouseEnter = (index: number) => {
    if (hoverRowIndex !== index) {
      setHoverRowIndex(index);
    }
  };

  const handleRowMouseLeave = (index: number) => {
    if (hoverRowIndex === index) {
      setHoverRowIndex(null);
    }
  };

  const handleOnOpenInFormBuilder: FormsTableMenuListProps["onOpenInFormBuilder"] =
    (index) => {
      rowClickHandlers[index]();
    };

  return (
    <TableContainer>
      <Table data-test-id={dataTestId} colorScheme="gray">
        <colgroup>
          <col span={1} style={{ width: "48px" }} />
          <col span={1} style={{ minWidth: "200px" }} />
          <col span={1} style={{ minWidth: "300px" }} />
          <col span={1} style={{ width: "200px" }} />
          <col span={1} style={{ width: "210px" }} />
        </colgroup>

        <Thead>
          <Tr>
            {headers.map((header, idx) => (
              <Th key={idx}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rowsData.map((rowData, idx) => (
            <Tr
              key={idx}
              onClick={() => handleRowClick(idx)}
              onDoubleClick={rowClickHandlers[idx]}
              onMouseEnter={() => handleRowMouseEnter(idx)}
              onMouseLeave={() => handleRowMouseLeave(idx)}
            >
              <Td
                key={`${idx}-action`}
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                {rowsAllowedActions[idx].privilegeApprovals &&
                rowsAttentionItems[idx].privilegeApprovalsNeeded ? (
                  <Tooltip
                    label="Sharing approvals are awaiting your action."
                    aria-label="Sharing approvals are awaiting your action."
                    openDelay={tooltipOpenDelay}
                  >
                    <Icon as={ErrorOutlineOutlinedIcon} boxSize={5} />
                  </Tooltip>
                ) : null}
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                <Text>{rowData.title}</Text>
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                <Text>{rowData.description}</Text>
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                <VStack
                  alignItems={"flex-start"}
                  key={`form-timestamp-${idx}`}
                  spacing="0px"
                >
                  <Text fontSize="12px">
                    {new Date(rowData.updatedAt ?? now()).toDateString()}
                  </Text>
                  <Text fontSize="12px">
                    {new Date(rowData.updatedAt ?? now()).toLocaleTimeString()}
                  </Text>
                </VStack>
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  padding: "5px 25px",
                }}
              >
                <FormsTableMenuList
                  index={idx}
                  archivePanel={archivePanel}
                  rowCallbacks={rowCallbacks}
                  onOpenInFormBuilder={handleOnOpenInFormBuilder}
                  rowsAttentionItems={rowsAttentionItems}
                  hoverRowIndex={hoverRowIndex}
                  rowsAllowedActions={rowsAllowedActions}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
