import { useState } from "react";
import { Icon, Tooltip, Circle } from "@chakra-ui/react";
import { ROLE_1, ROLE_2 } from "../roles";
import { NewCommentInput } from "./NewCommentInput";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { IAddNewConversation } from "./ElementConversations";

const styles = {
  baseButtonStyle: {
    backgroundColor: "white",
    marginLeft: -2,
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 20,
  },
};

export const NewConversationButton = ({
  isSelected,
  isHovered,
  userRoleOnDocument,
  addNewConversation,
}: {
  isSelected: boolean;
  isHovered: boolean;
  userRoleOnDocument: string;
  elementId: string;
  addNewConversation: (x: IAddNewConversation) => void;
}) => {
  const [open, setOpen] = useState(false);

  const [newCommentInputVisible, setNewCommentInputVisible] = useState(false);
  const [newCommentInputVisibleTwo, setNewCommentInputVisibleTwo] =
    useState(false);
  const [conversationType, setConversationType] = useState("");

  const pathname = window.location.pathname;
  const isFormBuilder = pathname.includes("form-builder");

  const MultipleRoleConversationButtons = (
    <div className="conversation-buttons">
      <div
        style={{
          ...styles.baseButtonStyle,
          position: "absolute",
          opacity: open && isSelected ? 1 : 0,
          marginTop: open && isSelected ? -21 : 0,
          transition: "margin-top 0.3s ease-in-out, opacity 0.3s ease-in-out",
          zIndex: 10,
        }}
      >
        <Tooltip label={`Leave a comment for ${ROLE_1}`}>
          <Circle
            data-test-id={`multiple-roles-new-convo-btn-${ROLE_1}`}
            bg="white"
            zIndex="10"
            size="32px"
            onClick={() => {
              setOpen(false);
              setNewCommentInputVisible(!newCommentInputVisible);
              setNewCommentInputVisibleTwo(false);
              setConversationType(ROLE_1);
            }}
            style={{
              position: "absolute",
              cursor: "pointer",
            }}
            border="1px"
            borderColor="gray.600"
            boxShadow="1px 1px 1px 1px rgba(128,128,128,0.69)"
            color="brightblue.500"
          >
            <Icon as={ChecklistOutlinedIcon} boxSize={4} />
          </Circle>
        </Tooltip>
      </div>

      <div
        style={{
          ...styles.baseButtonStyle,
          position: "absolute",
          marginTop: open && isSelected ? 20 : 0,
          opacity: isHovered || isSelected ? 1 : 0,
          transition: "margin-top 0.3s ease-in-out, opacity 0.3s ease-in-out",
          zIndex: 10,
        }}
      >
        <Tooltip label={`Leave a comment for reviewers`}>
          <Circle
            data-test-id={`multiple-roles-new-convo-btn-${ROLE_2}`}
            bg="white"
            size="32px"
            onClick={() => {
              setOpen(false);
              setNewCommentInputVisible(false);
              setNewCommentInputVisibleTwo(!newCommentInputVisibleTwo);
              setConversationType(ROLE_2);
            }}
            style={{
              position: "absolute",
              cursor: "pointer",
            }}
            border="1px"
            borderColor="gray.600"
            boxShadow="1px 1px 1px 1px rgba(128,128,128,0.69)"
            color={isSelected ? "brightblue.500" : "gray.300"}
          >
            <Icon as={BalanceOutlinedIcon} boxSize={4} />
          </Circle>
        </Tooltip>
      </div>

      <div
        style={{
          ...styles.baseButtonStyle,
          opacity: open ? 0 : isHovered || isSelected ? 1 : 0,
          transition: "opacity 0.3s ease-in-out, color 0.3s ease-in-out",
          position: "absolute",
          zIndex: 10,
        }}
      >
        <Circle
          bg="white"
          size="32px"
          data-test-id="multiple-roles-new-convo-btn"
          onClick={() => {
            setOpen(!open);
          }}
          style={{
            position: "absolute",
            cursor: "pointer",
          }}
          border="1px"
          borderColor={isSelected ? "gray.600" : "white"}
          boxShadow="1px 1px 1px 1px rgba(128,128,128,0.69)"
          color={isSelected ? "brightblue.500" : "gray.300"}
        >
          <Icon
            as={AddCommentOutlinedIcon}
            boxSize={5}
            style={{ transform: "scaleX(-1)" }}
          />
        </Circle>
      </div>
      <div style={{ position: "absolute", left: 40 }}>
        <div
          style={{
            backgroundColor: "white",
            padding: 4,
            borderRadius: 2,
            minWidth: "400px",
            opacity:
              (newCommentInputVisible || newCommentInputVisibleTwo) &&
              isSelected
                ? 1
                : 0,
            boxShadow:
              newCommentInputVisible || newCommentInputVisibleTwo
                ? "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
                : "none",
            transition: "opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          }}
        >
          <NewCommentInput
            onSend={(newCommentText, assignedTo, mentioned) => {
              addNewConversation({
                newCommentText,
                type: conversationType,
                assignedTo,
                mentioned,
              });
            }}
            placeholderText={`Start new ${conversationType} conversation`}
          />
        </div>
      </div>
    </div>
  );

  const SingleRoleConversationButton = (
    <div style={{ zIndex: 10 }}>
      <div
        style={{
          ...styles.baseButtonStyle,
          opacity: open ? 0 : isHovered || isSelected ? 1 : 0,
          transition: "opacity 0.3s ease-in-out, color 0.3s ease-in-out",
          position: "absolute",
        }}
      >
        <Tooltip label={`Leave a comment`}>
          <Circle
            data-test-id="single-role-new-convo-btn"
            bg="white"
            size="32px"
            onClick={() => {
              setOpen(false);
              setNewCommentInputVisible(!newCommentInputVisible);
              setConversationType(userRoleOnDocument);
            }}
            style={{
              position: "absolute",
              cursor: "pointer",
            }}
            border="1px"
            borderColor="gray.600"
            boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2)"
            color="brightblue.500"
          >
            <Icon
              as={AddCommentOutlinedIcon}
              boxSize={5}
              style={{ transform: "scaleX(-1)" }}
            />
          </Circle>
        </Tooltip>
      </div>
      <div style={{ position: "absolute", left: 40 }}>
        <div
          style={{
            backgroundColor: "white",
            padding: 4,
            borderRadius: 2,
            minWidth: "400px",
            opacity: newCommentInputVisible && isSelected ? 1 : 0,
            boxShadow: newCommentInputVisible
              ? "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
              : "none",
            transition: "opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          }}
        >
          <NewCommentInput
            onSend={(commentText, assignedTo, mentioned) => {
              addNewConversation({
                newCommentText: commentText,
                type: conversationType,
                assignedTo,
                mentioned,
              });
            }}
            placeholderText={`Start new conversation`}
          />
        </div>
      </div>
    </div>
  );

  return isFormBuilder || userRoleOnDocument == "submitter"
    ? SingleRoleConversationButton
    : MultipleRoleConversationButtons;
};
