import {
  Box,
  Center,
  HStack,
  Icon,
  MenuList,
  Menu,
  MenuItem,
  MenuButton,
  VStack,
  Wrap,
  WrapItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useState } from "react";
import { useAppSelector, RootState, useAppDispatch } from "src/store";
import {
  selectOpenCount,
  selectResolvedCount,
  selectPrimaryCommentFilter,
  selectSecondaryCommentFilter,
  selectAssignedCount,
  selectParticipatingCount,
} from "src/store/selectors/comments.selectors";
import {
  updatePrimaryCommentFilter,
  updateSecondaryCommentFilter,
} from "src/store/slices/comments.slice";

export const CommentFilter = () => {
  const [commentButtonActive, setCommentButtonActive] = useState(false);
  const globalUserInfo = useAppSelector((state) => state.user.userContext);
  const dispatch = useAppDispatch();
  const primaryCommentFilter = useAppSelector(selectPrimaryCommentFilter);
  const secondaryCommentFilter = useAppSelector(selectSecondaryCommentFilter);
  const openCount = useAppSelector(selectOpenCount);
  const resolvedCount = useAppSelector(selectResolvedCount);
  const assignedOpenCount = useAppSelector((state: RootState) =>
    selectAssignedCount(
      state,
      globalUserInfo?.user.email ?? "",
      globalUserInfo?.user.lastName ?? "",
      false
    )
  );
  const assignedResolvedCount = useAppSelector((state: RootState) =>
    selectAssignedCount(
      state,
      globalUserInfo?.user.email ?? "",
      globalUserInfo?.user.lastName ?? "",
      true
    )
  );
  const participatingResolvedCount = useAppSelector((state: RootState) =>
    selectParticipatingCount(
      state,
      globalUserInfo?.user.email ?? "",
      globalUserInfo?.user.lastName ?? "",
      true
    )
  );

  const participatingOpenCount = useAppSelector((state: RootState) =>
    selectParticipatingCount(
      state,
      globalUserInfo?.user.email ?? "",
      globalUserInfo?.user._id ?? "",
      false
    )
  );

  function getCommentProgress() {
    let count: number | undefined = 0;
    switch (primaryCommentFilter) {
      case "all conversations":
        count = getAllConversationsCount();
        break;
      case "conversations assigned to you":
        count = getAssignedConversationsCount();
        break;
      case "your conversations":
        count = getForConversationsCount();
        break;
    }

    if (count && count > 0) {
      if (count > 9) {
        return "9+";
      } else {
        return `${count}`;
      }
    }
    return "";
  }

  function getAssignedConversationsCount() {
    switch (secondaryCommentFilter) {
      case "open":
        return assignedOpenCount;
      case "resolved":
        return assignedResolvedCount;
      case "open or resolved":
        return assignedOpenCount + assignedResolvedCount;
    }
  }

  function getForConversationsCount() {
    switch (secondaryCommentFilter) {
      case "open":
        return participatingOpenCount;
      case "resolved":
        return participatingResolvedCount;
      case "open or resolved":
        return participatingOpenCount + participatingResolvedCount;
    }
  }

  function getOpenConversationsCount() {
    switch (primaryCommentFilter) {
      case "all conversations":
        return openCount;
      case "conversations assigned to you":
        return assignedOpenCount;
      case "your conversations":
        return participatingOpenCount;
    }
  }

  function getResolvedConversationsCount() {
    switch (primaryCommentFilter) {
      case "all conversations":
        return resolvedCount;
      case "conversations assigned to you":
        return assignedResolvedCount;
      case "your conversations":
        return participatingResolvedCount;
    }
  }

  function getOpenOrResolvedConversationsCount() {
    switch (primaryCommentFilter) {
      case "all conversations":
        return openCount + resolvedCount;
      case "conversations assigned to you":
        return assignedOpenCount + assignedResolvedCount;
      case "your conversations":
        return participatingOpenCount + participatingResolvedCount;
    }
  }

  function getAllConversationsCount() {
    switch (secondaryCommentFilter) {
      case "open":
        return openCount;
      case "resolved":
        return resolvedCount;
      case "open or resolved":
        return openCount + resolvedCount;
    }
  }
  const iconWH = "15px";
  return (
    <Popover
      onClose={() => setCommentButtonActive(false)}
      isOpen={commentButtonActive}
    >
      <PopoverTrigger>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            position: "sticky",
          }}
        >
          <Icon
            color="white"
            as={ChatBubbleOutlineOutlinedIcon}
            onClick={() => {
              setCommentButtonActive(true);
            }}
          />

          <div style={{ zIndex: -2, position: "absolute" }}>
            <Box w={iconWH} h={iconWH}>
              <Text
                data-test-id="comment-filter-progress"
                fontSize="10px"
                fontWeight={1000}
                ml="3px"
                mt="-3px"
              >
                {getCommentProgress()}
              </Text>
            </Box>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent bg="white">
        <VStack textColor="black" m="24px" align="left">
          <HStack>
            <Wrap>
              <WrapItem>
                <Center h="100%" fontSize="sm">
                  Show
                </Center>
              </WrapItem>
              <WrapItem>
                <Menu>
                  <MenuButton
                    border="1px"
                    borderRadius="3px"
                    p="4px"
                    borderColor="gray.500"
                    fontSize="sm"
                  >
                    {primaryCommentFilter}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          updatePrimaryCommentFilter("all conversations")
                        );
                      }}
                      fontSize="sm"
                    >
                      all conversations ({getAllConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          updatePrimaryCommentFilter(
                            "conversations assigned to you"
                          )
                        );
                      }}
                      fontSize="sm"
                    >
                      conversations assigned to you (
                      {getAssignedConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          updatePrimaryCommentFilter("your conversations")
                        );
                      }}
                      fontSize="sm"
                    >
                      your conversations ({getForConversationsCount()})
                    </MenuItem>
                  </MenuList>
                </Menu>
              </WrapItem>
              <WrapItem>
                <Center h="100%" fontSize="sm">
                  that are
                </Center>
              </WrapItem>
              <WrapItem>
                <Menu>
                  <MenuButton
                    border="1px"
                    borderRadius="3px"
                    p="4px"
                    borderColor="gray.500"
                    fontSize="sm"
                  >
                    {secondaryCommentFilter}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        dispatch(updateSecondaryCommentFilter("open"));
                      }}
                      fontSize="sm"
                    >
                      open ({getOpenConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(updateSecondaryCommentFilter("resolved"));
                      }}
                      fontSize="sm"
                    >
                      resolved ({getResolvedConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          updateSecondaryCommentFilter("open or resolved")
                        );
                      }}
                      fontSize="sm"
                    >
                      open or resolved ({getOpenOrResolvedConversationsCount()})
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Center h="100%" fontSize="sm">
                  .
                </Center>
              </WrapItem>
            </Wrap>
          </HStack>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};
