import React from "react";
import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { useGetFormByIdQuery } from "src/services/forms.service";
import { PageLoader } from "src/lib/components/PageLoader";
import { FormBuilderTitle } from "src/components/FormBuilderTitle";
import { useAppDispatch, useAppSelector } from "src/store";
import { FormBuilder } from "./FormBuilder";
import { FormActions } from "./FormActions";
import {
  selectFormErrorMessage,
  selectFormsEditingElementId,
  selectFormStatus,
} from "src/store/selectors/form.selectors";
import { setErrorText } from "src/store/slices/snackbar-text.slice";

interface FormBuilderDataPageProps {
  formId: string;
}

export const FormBuilderDatePage = ({ formId }: FormBuilderDataPageProps) => {
  const userId = useAppSelector((state) => state.user.userContext?.user?._id);
  const formErrorMessage = useAppSelector(selectFormErrorMessage);
  const formStatus = useAppSelector(selectFormStatus);
  const editingElementId = useAppSelector(selectFormsEditingElementId);
  const dispatch = useAppDispatch();

  const { data, error, isLoading } = useGetFormByIdQuery(
    { formId },
    {
      pollingInterval: 5000,
      skip: Boolean(editingElementId) || formStatus === "saving",
    }
  );

  React.useEffect(() => {
    if (error || formErrorMessage) {
      dispatch(setErrorText(formErrorMessage ?? "Error getting form data"));
    }
  }, [error, dispatch, formErrorMessage]);

  return (
    <AuthenticatedPage
      navBarLeftAlignedContent={<FormBuilderTitle />}
      navBarRightAlignedContent={<FormActions />}
    >
      {!error && isLoading ? <PageLoader /> : null}

      {!isLoading && data && userId ? <FormBuilder /> : null}
    </AuthenticatedPage>
  );
};
