import { Center, HStack, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import { CommentFilter } from "src/components/CommentFilter";
import { CreateSubmissionModal } from "./Modal/CreateSubmissionModal";
import { useAppDispatch, useAppSelector } from "src/store";
import { SaveIndicator } from "./TopNavBar/SaveIndicator";
import {
  setDisplayPrivilegeBanner,
  setDisplayPrivilegeConfirmation,
  toggleIsRulesDrawerOpen,
} from "src/store/slices/form.slice";
import {
  selectFormStatus,
  selectDisplayPrivilegeBanner,
  selectForm,
  selectFormId,
  selectFormMeta,
  selectFormReadOnly,
} from "src/store/selectors/form.selectors";
import React from "react";
import { SharingModal } from "./Modal/SharingModal";
import { FormPrivilegeAction } from "./FormPrivilegeAction";
import { SaveStatus } from "src/constants/status.constants";

export const FormActions = () => {
  const formId = useAppSelector(selectFormId);
  const form = useAppSelector(selectForm);
  const formReadOnly = useAppSelector(selectFormReadOnly);
  const formMeta = useAppSelector(selectFormMeta);
  const formStatus = useAppSelector(selectFormStatus);
  const displayPrivilegeBanner = useAppSelector(selectDisplayPrivilegeBanner);
  const dispatch = useAppDispatch();
  const {
    isOpen: submissionModalIsOpen,
    onOpen: openSubmissionModal,
    onClose: closeSubmissionModal,
  } = useDisclosure();

  const {
    isOpen: sharingModalIsOpen,
    onOpen: openSharingModal,
    onClose: closeSharingModal,
  } = useDisclosure();

  const handleRulesIconClick = React.useCallback(() => {
    dispatch(toggleIsRulesDrawerOpen(null));
  }, [dispatch]);

  return (
    <HStack m="12px" color="white.500">
      <Center>
        <HStack>
          <SaveIndicator
            saveStatus={
              formStatus === "saving"
                ? SaveStatus.inProgress
                : formStatus === "success"
                  ? SaveStatus.saved
                  : SaveStatus.unsavedChanges
            }
            savedOnce={formId || ""}
          />
          {formReadOnly?.actions.submit ? (
            <Tooltip
              label="Submit Form or Assign Submission"
              aria-label="Submit form or assign submission."
            >
              <Icon
                as={AssignmentIndOutlinedIcon}
                onClick={openSubmissionModal}
              />
            </Tooltip>
          ) : null}
          <Tooltip label="Share Form" aria-label="Share form.">
            <Icon
              data-test-id="share-form-button"
              as={PersonAddAlt1OutlinedIcon}
              onClick={openSharingModal}
            />
          </Tooltip>
          <Tooltip
            label="Assign Rules to Document Elements"
            aria-label="Rules."
          >
            <Icon
              data-test-id="rule-builder-btn"
              as={RuleFolderOutlinedIcon}
              onClick={handleRulesIconClick}
            />
          </Tooltip>

          <Tooltip
            label="View and Filter Comments"
            aria-label="View and filter comments"
          >
            {/* div is necessary for the tooltip to display */}
            <div>
              <CommentFilter />
            </div>
          </Tooltip>
          {formReadOnly?.actions.privilegeApprovals ? (
            <Tooltip
              label="Manage Privilege Assertion"
              aria-label="Manage privilege assertion"
            >
              {/* div is necessary for the tooltip to display */}
              <div>
                <FormPrivilegeAction
                  formId={formId || ""}
                  displayPrivilegeBanner={displayPrivilegeBanner || false}
                  privilegeWarningDismissed={
                    formMeta?.privilegeWarningDismissed || false
                  }
                  setDisplayPrivilegeBanner={(b) =>
                    dispatch(setDisplayPrivilegeBanner(b))
                  }
                  setDisplayPrivilegeConfirmation={(b) =>
                    dispatch(setDisplayPrivilegeConfirmation(b))
                  }
                />
              </div>
            </Tooltip>
          ) : null}
        </HStack>
      </Center>

      {formMeta ? (
        <CreateSubmissionModal
          isOpen={submissionModalIsOpen}
          onClose={closeSubmissionModal}
          formId={formId || ""}
          formName={formMeta?.title}
          becomeAdjudicator={formReadOnly?.actions.becomeAdjudicator || false}
        />
      ) : null}

      {sharingModalIsOpen && form ? (
        <SharingModal
          document={form}
          type="form"
          isOpen={sharingModalIsOpen}
          onClose={closeSharingModal}
        />
      ) : null}
    </HStack>
  );
};
