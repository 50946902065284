import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Text,
  Tooltip,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "./Spacer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ROLE_1 } from "../roles";
import { RichTextEditor } from "./RichTextEditor";
import { RichTextDelta } from "src/types/form.types";

export const AdvancedNotes = ({
  updateReviewerNotes,
  updateSubmitterNotes,
  surveyTakerNotes,
  adjudicatorNotes,
  dataTestIdPathIndex,
}: {
  updateReviewerNotes: (e: RichTextDelta) => void;
  updateSubmitterNotes: (e: RichTextDelta) => void;
  surveyTakerNotes: RichTextDelta;
  adjudicatorNotes: RichTextDelta;
  dataTestIdPathIndex?: string;
}) => {
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="row"
        alignItems={"flex-end"}
        justifyContent={"space-between"}
      >
        <HStack>
          <Text contentEditable={false}>Notes and Instructions</Text>
          <Tooltip
            label="Optional notes and instructions for the form assignee. This text is never included in the final document output."
            fontSize="xs"
          >
            <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
          </Tooltip>
        </HStack>
      </Flex>
      <Spacer y={SpacerSizes.sm} />
      <Tabs borderColor="gray.200" borderWidth="2px" colorScheme="brightblue">
        <TabList gap={0} contentEditable={false} style={{ userSelect: "none" }}>
          <Tab>
            <Icon boxSize={4} as={ChecklistOutlinedIcon} color="gray.500" />
            <Spacer x={SpacerSizes.xs} />
            <Text variant={"caption"}>
              {ROLE_1.charAt(0).toUpperCase()}
              {ROLE_1.slice(1)}
            </Text>
          </Tab>
          <Tab>
            <Icon boxSize={4} as={BalanceOutlinedIcon} color="gray.500" />
            <Spacer x={SpacerSizes.xs} />
            <Text variant="caption">Reviewer</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel style={{ minHeight: 150, padding: 0 }}>
            <RichTextEditor
              dataTestId={`survey-taker-notes-${dataTestIdPathIndex}`}
              value={surveyTakerNotes}
              onChange={updateSubmitterNotes}
            />
          </TabPanel>
          <TabPanel style={{ minHeight: 150, padding: 0 }}>
            <RichTextEditor
              dataTestId={`reviewer-notes-${dataTestIdPathIndex}`}
              value={adjudicatorNotes}
              onChange={updateReviewerNotes}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
