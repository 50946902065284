import { Dispatch, SetStateAction } from "react";
import { MembershipDetail } from "../types";
import { Center, Checkbox, Icon, Text } from "@chakra-ui/react";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

function BooleanCheckbox({ val }: { val: boolean }) {
  if (val) {
    return <Checkbox isDisabled defaultChecked />;
  }
  return <Checkbox isDisabled />;
}

const tdFontSize = "12";
const cellTextColor = "gray.700";
const tdStyleEven = {
  border: "1px solid var(--chakra-colors-gray-600)",
  padding: "8px",
  background: "white",
};

const tdStyleOdd = {
  border: "1px solid var(--chakra-colors-gray-600)",
  padding: "8px",
  background: "var(--chakra-colors-gray-200)",
};

export default function MembersTableBody({
  membershipData,
  setMembershipToEdit,
  openAddUserModal,
}: {
  membershipData: MembershipDetail[];
  setMembershipToEdit: Dispatch<SetStateAction<MembershipDetail | undefined>>;
  openAddUserModal: () => void;
}) {
  return (
    <tbody>
      {membershipData.map((membership, i) => {
        return (
          <tr key={membership.userId}>
            <td>
              <Center color={cellTextColor}>
                <Icon
                  data-test-id={`edit-member-button-${membership.user.email}`}
                  as={EditOutlinedIcon}
                  w="16px"
                  h="16px"
                  color="brightblue.500"
                  onClick={() => {
                    setMembershipToEdit(membership);
                    openAddUserModal();
                  }}
                />
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <Text fontSize={tdFontSize}>
                  {membership.user.firstName} {membership.user.lastName}
                </Text>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <Text fontSize={tdFontSize}>{membership.user.email}</Text>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <Text fontSize={tdFontSize}>{membership.membershipType}</Text>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <Text fontSize={tdFontSize}>
                  {membership.user.accountStatus}
                </Text>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                {membership.membershipExpiration ? (
                  <Text
                    fontSize={tdFontSize}
                    data-test-id={`membership-expiration-${membership.user.email}`}
                  >
                    {moment(membership.membershipExpiration)
                      .local()
                      .format("ddd YYYY-MM-DD HH:mm:ss")}
                  </Text>
                ) : (
                  <></>
                )}
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.manageOrgPermissions}
                ></BooleanCheckbox>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.manageOrgSettings}
                ></BooleanCheckbox>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.inviteUser}
                ></BooleanCheckbox>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.createOrCopyForms}
                ></BooleanCheckbox>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.manageLegalPrivilege}
                ></BooleanCheckbox>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.seeAllMembers}
                ></BooleanCheckbox>
              </Center>
            </td>
            <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
              <Center color={cellTextColor}>
                <BooleanCheckbox
                  val={membership.permissions.seeAllGuests}
                ></BooleanCheckbox>
              </Center>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}
