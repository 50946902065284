import { PathIndex } from "src/types";
import { romanize } from "./romanize.utils";
import { nanoid } from "nanoid";
import { SubmissionElement } from "src/types/submission.types";
import { SubmissionElementState } from "src/store/slices/submission.slice";

export const transformSubmissionElementsToElementState = (
  elements: SubmissionElement[],
  activeSubmissionElements?: SubmissionElement[],
  parentElementId?: string
): SubmissionElementState[] => {
  const allSubmissionElements = activeSubmissionElements ?? elements;
  return elements.map((e) => {
    const indentationLevel = getIndentationLevel(e.id, allSubmissionElements);

    const pathIndex = getPathIndexForElementId({
      targetElementId: e.id,
      elements: allSubmissionElements,
    });
    return {
      id: e.id,
      parentElementId: parentElementId || null,
      indentationLevel,
      pathIndex: e.pathIndex || pathIndex || [],
      titleNumberPathIndex: [],
      type: e.type,
      children: transformSubmissionElementsToElementState(
        e.children || [],
        allSubmissionElements,
        e.id
      ),
    };
  });
};

export const getTitleNumber = (path: PathIndex): string => {
  let titleNumber = "";
  path.forEach((levelIndex, nestingLevel) => {
    const levelIndexNumber = levelIndex + 1;

    titleNumber += [
      levelIndexNumber,
      String.fromCharCode(96 + levelIndexNumber),
      romanize(levelIndexNumber),
      levelIndexNumber,
    ][nestingLevel];

    titleNumber += ".";
  });

  return titleNumber;
};

interface GetPathIndexForElementIdParams {
  targetElementId: string;
  elements: SubmissionElement[];
  currentPathIndex?: PathIndex;
}

export const getPathIndexForElementId = ({
  targetElementId,
  elements,
  currentPathIndex = [],
}: GetPathIndexForElementIdParams): PathIndex | null => {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const pathIndex = [...currentPathIndex, i];
    if (element.id === targetElementId) {
      return pathIndex;
    }

    const childPathIndex = getPathIndexForElementId({
      targetElementId,
      elements: element.children || [],
      currentPathIndex: pathIndex,
    });

    if (childPathIndex) {
      return [...childPathIndex];
    }
  }
  return null;
};

export const getSubmissionElementsById = (
  elements: SubmissionElement[],
  parentElementId?: string
) => {
  let elementsById: { [key: string]: SubmissionElement } = {};

  elements.forEach((element) => {
    if (!element.id) {
      throw new Error("Submission Element does not have ID set");
    }

    if (element.id in elementsById) {
      throw new Error("Submission Element is already in elementsById");
    }

    elementsById[element.id] = {
      ...element,
      parentElementId: parentElementId || null,
    };

    const childrenElementsById = element?.children
      ? getSubmissionElementsById(element.children, element.id)
      : null;

    elementsById = {
      ...elementsById,
      ...childrenElementsById,
    };
  });

  return elementsById;
};

export const getElementPathIndexes = (
  elementIds: string[],
  submissionElements: SubmissionElement[] | SubmissionElementState[] | undefined
): PathIndex[] => {
  const findPathIndex = (
    id: string,
    elements: SubmissionElement[] | SubmissionElementState[],
    currentPath: PathIndex = []
  ): PathIndex | null => {
    for (let i = 0; i < elements?.length; i++) {
      const element = elements[i];

      if (element.id === id) {
        return [...currentPath, i];
      }

      if (element.children) {
        const childPath = findPathIndex(id, element.children, [
          ...currentPath,
          i,
        ]);

        if (childPath) {
          return childPath;
        }
      }
    }

    return null;
  };

  if (!submissionElements) {
    return [];
  }

  return elementIds.map((id) => findPathIndex(id, submissionElements) || []);
};

export const getIndentationLevelBak = (
  elementId: string,
  submissionElements: SubmissionElement[]
): number => {
  return getElementPathIndexes([elementId], submissionElements)[0].length;
};

export const getElementAndParent = (
  pathIndex: PathIndex,
  currentElements: SubmissionElementState[]
): [SubmissionElementState | undefined, SubmissionElementState | undefined] => {
  let parentElement: SubmissionElementState | undefined;
  let targetElement: SubmissionElementState | undefined;

  for (let i = 0; i < pathIndex.length; i++) {
    const index = pathIndex[i];

    if (index < 0 || index >= currentElements.length) {
      return [undefined, undefined];
    }

    parentElement = targetElement;
    targetElement = currentElements[index];
    currentElements = targetElement.children || [];
  }

  return [targetElement, parentElement];
};

export const getIndentationLevel = (
  elementId: string,
  elements: SubmissionElement[] | undefined
): number => {
  if (!elements) return 0;
  const element = findElementById(elementId, elements);
  if (!element) return 0;
  return element.pathIndex?.length ?? 0;
};

export const findElementById = (
  elementId: string,
  elements: SubmissionElement[]
): SubmissionElement | null => {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (element.id === elementId) {
      return element;
    } else if (element.children) {
      const foundElement = findElementById(elementId, element.children);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return null;
};

export const getElementId = () => {
  return nanoid(16);
};

export const reconstructSubmissionElements = (
  elementsById: { [elementId: string]: SubmissionElement },
  submissionElementState: SubmissionElementState[]
): SubmissionElement[] => {
  const recursiveReconstruct = (
    elements: SubmissionElementState[]
  ): SubmissionElement[] => {
    const reconstructedElements = elements.map(
      (element: SubmissionElementState) => {
        return {
          ...elementsById[element.id],
          children: element.children
            ? recursiveReconstruct(element.children)
            : null,
        } as SubmissionElement;
      }
    );

    return reconstructedElements;
  };

  return recursiveReconstruct(submissionElementState);
};
