import { Heading, Card, CardBody } from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { Spacer, SpacerSizes } from "./Spacer";
import { Colors } from "../theme/colors";
import { useNavigate } from "react-router-dom";

export const EmptySubmissionsSection = () => {
  const navigate = useNavigate();

  return (
    <Card
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/forms`);
      }}
    >
      <CardBody
        style={{
          backgroundColor: "white",
          border: "dashed",
          borderWidth: "1px",
          borderColor: Colors.brand[500],
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Heading variant="h2" color="brand.500">
          You have no Submissions in progress, create one from a Form.
        </Heading>
        <Spacer x={SpacerSizes.md} />
        <PlusSquareIcon w="40px" h="40px" color="brand.500" />
      </CardBody>
    </Card>
  );
};
