import {
  transformDataResponse,
  transformErrorResponse,
} from "src/util/base-query.utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Credentials {
  email: string;
}

export const authenticateApi = createApi({
  reducerPath: "@authenticateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/public`,
  }),
  endpoints: (builder) => ({
    authenticate: builder.mutation<string, Credentials>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: transformDataResponse<string>,
      transformErrorResponse: transformErrorResponse,
    }),
  }),
});

export const { useAuthenticateMutation } = authenticateApi;
