export const getAgreedSubmissionPrivileges = () => {
  const submissionPrivilegeWarningDismissals = localStorage.getItem(
    "submissionPrivilegeWarningDismissals"
  );
  const submissionPrivilegeDismissalsArray: string[] = JSON.parse(
    submissionPrivilegeWarningDismissals ?? "[]"
  );

  return submissionPrivilegeDismissalsArray;
};

export const displayPrivilegeForSubmission = (
  submissionId: string,
  isPrivileged: boolean | undefined
) => {
  const submissionPrivilegeWarningDismissals = getAgreedSubmissionPrivileges();
  return (
    !submissionPrivilegeWarningDismissals.includes(submissionId) && isPrivileged
  );
};

export const addAgreedSubmissionPrivilege = (submissionId: string) => {
  const submissionPrivilegeWarningDismissals = getAgreedSubmissionPrivileges();
  submissionPrivilegeWarningDismissals.push(submissionId);

  localStorage.setItem(
    "submissionPrivilegeWarningDismissals",
    JSON.stringify(submissionPrivilegeWarningDismissals)
  );
};

export const getAgreedFormPrivileges = () => {
  const formPrivilegeWarningDismissals = localStorage.getItem(
    "formPrivilegeWarningDismissals"
  );
  const formPrivilegeDismissalsArray: string[] = JSON.parse(
    formPrivilegeWarningDismissals ?? "[]"
  );

  return formPrivilegeDismissalsArray;
};

export const displayPrivilegeForForm = (
  formId: string,
  isPrivileged: boolean | undefined
) => {
  const formPrivilegeWarningDismissals = getAgreedFormPrivileges();
  return !formPrivilegeWarningDismissals.includes(formId) && isPrivileged;
};

export const addAgreedFormPrivilege = (formId: string) => {
  const formPrivilegeWarningDismissals = getAgreedFormPrivileges();
  formPrivilegeWarningDismissals.push(formId);

  localStorage.setItem(
    "formPrivilegeWarningDismissals",
    JSON.stringify(formPrivilegeWarningDismissals)
  );
};

export const clearPrivilegeFromStorage = () => {
  localStorage.removeItem("formPrivilegeWarningDismissals");
  localStorage.removeItem("submissionPrivilegeWarningDismissals");
};
