import React from "react";
import { setSessionAndToken } from "../store/slices/auth.slice";
import { supabaseClient } from "../clients/supabase.client";
import { useAppDispatch } from "../store";

export const useSession = () => {
  const [sessionLoaded, setSessionLoaded] = React.useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const getSupabaseSession = async () => {
      /**
       * Since `supbaseClient.auth.getSession()` is async
       * we don't want to allow rendering until that has returned
       * otherwise, a user will get pushed to /login and then routed back
       * to the dashboard once the session has been loaded.
       *
       * We're doing this by blocking any children rendering
       * with `sessionLoaded` flag in local state.
       **/
      const {
        data: { session },
      } = await supabaseClient.auth.getSession();

      dispatch(
        setSessionAndToken({
          token: session?.access_token ?? null,
          session,
        })
      );

      if (session?.access_token) {
        localStorage.setItem("token", session?.access_token);
      }
      setSessionLoaded(true);
    };

    getSupabaseSession();
  }, [dispatch]);

  return {
    sessionLoaded,
  };
};
