import { CommentThread, SlateObject } from "../types";
import {
  Collapse,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { NewCommentInput } from "./NewCommentInput";
import { ROLE_1, ROLE_2 } from "../roles";
import { useState } from "react";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { Conversation } from "./Conversation";

export interface IAddNewConversation {
  newCommentText: SlateObject;
  type: string;
  assignedTo?: string[];
  mentioned?: string[];
}

export interface IResolveConversation {
  commentThread: CommentThread;
}

export interface IAddNewComment {
  commentThread: CommentThread;
  newComment: SlateObject;
  assignedTo?: string[];
  mentioned?: string[];
}

const ElementConversations = ({
  filteredCommentThreads,
  isSelected,
  addNewConversation,
  resolveConversation,
  addNewComment,
  userRoleOnDocument,
}: {
  filteredCommentThreads: CommentThread[];
  isSelected: boolean;
  addNewConversation: (x: IAddNewConversation) => void;
  addNewComment: (x: IAddNewComment) => void;
  resolveConversation: (x: IResolveConversation) => void;
  userRoleOnDocument: string;
}) => {
  const [tabOneCommentInputVisible, setTabOneCommentInputVisible] =
    useState(false);
  const [tabTwoCommentInputVisible, setTabTwoCommentInputVisible] =
    useState(false);
  const governanceRoles = [ROLE_2, "owner", "editor"];
  const businessRoles = [ROLE_1, "all"];

  const businessComments = filteredCommentThreads.filter((commentThread) =>
    businessRoles.includes(commentThread.type)
  );
  const governanceComments = filteredCommentThreads.filter((commentThread) =>
    governanceRoles.includes(commentThread.type)
  );

  const pathname = window.location.pathname;
  const isFormBuilder = pathname.includes("form-builder");

  return (
    <Collapse in={isSelected}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 2,
          minWidth: "400px",
          boxShadow: isSelected ? "0 4px 8px 0 rgba(0, 0, 0, 0.2)" : "none",
          zIndex: isSelected ? 10 : 0,
        }}
      >
        <Tabs w={"100%"}>
          <TabList>
            {(!isFormBuilder && userRoleOnDocument === ROLE_1) ||
            (isFormBuilder && governanceRoles.includes(userRoleOnDocument)) ? (
              <Tab>Conversations</Tab>
            ) : (
              <>
                <Tab>{ROLE_1}</Tab>
                <Tab>reviewer</Tab>
              </>
            )}
          </TabList>
          <TabPanels>
            {!isFormBuilder ? (
              <TabPanel flexDirection="column" alignContent={"flex-end"}>
                {businessComments.map((commentThread, i) => (
                  <Conversation
                    key={i}
                    commentThread={commentThread}
                    onResolve={() => resolveConversation({ commentThread })}
                    onSend={(
                      newComment: SlateObject,
                      commentThread: CommentThread,
                      assignedTo?: string[],
                      mentioned?: string[]
                    ) =>
                      addNewComment({
                        newComment,
                        commentThread,
                        assignedTo,
                        mentioned,
                      })
                    }
                  />
                ))}
                <Tooltip
                  label={`Start a new ${ROLE_1} conversation`}
                  aria-label="Add Comment"
                >
                  <Icon
                    onClick={() =>
                      setTabOneCommentInputVisible(!tabOneCommentInputVisible)
                    }
                    as={AddCommentOutlinedIcon}
                    boxSize={5}
                    color={"brightblue.500"}
                  />
                </Tooltip>
                <Collapse in={tabOneCommentInputVisible}>
                  <NewCommentInput
                    onSend={(commentText, assignedTo, mentioned) => {
                      addNewConversation({
                        newCommentText: commentText,
                        type: ROLE_1,
                        assignedTo,
                        mentioned,
                      });
                      setTabOneCommentInputVisible(false);
                    }}
                    placeholderText={`Start new ${ROLE_1} conversation`}
                  />
                </Collapse>
              </TabPanel>
            ) : null}
            {[ROLE_2, "owner", "editor"].includes(userRoleOnDocument) ? (
              <TabPanel justifyItems={"flex-end"}>
                {governanceComments.map((commentThread, i) => (
                  <Conversation
                    key={i}
                    commentThread={commentThread}
                    onResolve={() => resolveConversation({ commentThread })}
                    onSend={(
                      newComment: SlateObject,
                      commentThread: CommentThread,
                      assignedTo?: string[],
                      mentioned?: string[]
                    ) =>
                      addNewComment({
                        newComment,
                        commentThread,
                        assignedTo,
                        mentioned,
                      })
                    }
                  />
                ))}
                <Tooltip
                  label={`Start a new ${ROLE_2} conversation`}
                  aria-label="Add Comment"
                >
                  <Icon
                    onClick={() =>
                      setTabTwoCommentInputVisible(!tabTwoCommentInputVisible)
                    }
                    as={AddCommentOutlinedIcon}
                    boxSize={5}
                    color={"brightblue.500"}
                  />
                </Tooltip>
                <Collapse in={tabTwoCommentInputVisible}>
                  <NewCommentInput
                    onSend={(newCommentText, assignedTo, mentioned) => {
                      addNewConversation({
                        newCommentText,
                        type: ROLE_2,
                        assignedTo,
                        mentioned,
                      });
                      setTabTwoCommentInputVisible(false);
                    }}
                    placeholderText={`Start new reviewer conversation`}
                  />
                </Collapse>
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
      </div>
    </Collapse>
  );
};

export default ElementConversations;
