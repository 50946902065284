import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./store/slices/auth.slice";
import { authenticateApi } from "./services/authenticate.service";
import { organizationSlice } from "./store/slices/organization.slice";
import { commentsSlice } from "./store/slices/comments.slice";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "./store/slices/user.slice";
import { organizationMembershipSlice } from "./store/slices/organization-membership.slice";
import { submissionSlice } from "./store/slices/submission.slice";
import { userApi } from "./services/user.service";
import { organizationApi } from "./services/organization.service";
import { formSlice } from "./store/slices/form.slice";
import { formsApi } from "./services/forms.service";
import { submissionsApi } from "./services/submission.service";
import { formMiddleware } from "./store/middleware/form.middleware";
import { submissionMiddleware } from "./store/middleware/submission.middleware";
import { commentsMiddleware } from "./store/middleware/comments.middleware";
import { selectedElementSlice } from "./store/slices/selected-element.slice";
import { snackBarTextSlice } from "./store/slices/snackbar-text.slice";
import { organizationMiddleware } from "./store/middleware/organization.middleware";
import { userMiddleware } from "./store/middleware/user.middleware";

export const store = configureStore({
  reducer: {
    [authenticateApi.reducerPath]: authenticateApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [submissionsApi.reducerPath]: submissionsApi.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    form: formSlice.reducer,
    submission: submissionSlice.reducer,
    organization: organizationSlice.reducer,
    organizationMembership: organizationMembershipSlice.reducer,
    comments: commentsSlice.reducer,
    selectedElement: selectedElementSlice.reducer,
    snackBarText: snackBarTextSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(
        formMiddleware.middleware,
        commentsMiddleware.middleware,
        submissionMiddleware.middleware,
        organizationMiddleware.middleware,
        userMiddleware.middleware
      )
      .concat(
        authenticateApi.middleware,
        userApi.middleware,
        organizationApi.middleware,
        formsApi.middleware,
        submissionsApi.middleware,
        organizationApi.middleware
      ),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type Selector<S> = (state: RootState) => S;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
