import React from "react";
import { useAppDispatch } from "../store";
import { setCurrentOrganizationId } from "../store/slices/user.slice";
import { getSlugMap } from "src/util/org-slugs.utils";
import { useLazyGetUserQuery } from "src/services/user.service";
import { OrganizationMembership } from "src/types/organization.types";

export const useActiveOrganization = () => {
  const [organizationLoaded, setOrganizationLoaded] = React.useState(false);
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();

  const updateSlugToOrgMap = React.useCallback(async () => {
    const userData = await getUser({ targetOrganizationId: null }).unwrap();

    const newSlugMap: { [key: string]: string } = {};

    userData.orgMemberships.forEach((membership: OrganizationMembership) => {
      if (membership.organization?.slug) {
        newSlugMap[membership.organization.slug] = membership.organization._id;
      }
    });

    localStorage.setItem("SlugToOrgIdMap", JSON.stringify(newSlugMap));
  }, [getUser]);

  React.useEffect(() => {
    const handleOrgSlug = async () => {
      const query = new URLSearchParams(location.search);
      const orgSlug = query.get("org");

      if (!localStorage.getItem("SlugToOrgIdMap")) {
        await updateSlugToOrgMap();
      }

      if (orgSlug) {
        let slugMap = getSlugMap();

        if (!slugMap[orgSlug]) {
          await updateSlugToOrgMap();
        }

        slugMap = getSlugMap();

        dispatch(setCurrentOrganizationId(slugMap[orgSlug]));
      }

      setOrganizationLoaded(true);
    };

    handleOrgSlug();
  }, [dispatch, setOrganizationLoaded, updateSlugToOrgMap]);

  return { organizationLoaded };
};
