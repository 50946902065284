import React from "react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { LuminosModalHeader } from "./Modal/LuminosModalHeader";
import { useUpdateFormPrivilegeMutation } from "src/services/forms.service";
import { FormPrivilegeDisabledBody } from "./FormPrivilegeDisabledBody";
import { FormPrivilegeEnabledBody } from "./FormPrivilegeEnabeldBody";

interface FormPrivilegeConfigModalProps {
  formId: string;
  isOpen: boolean;
  currentDocumentIsPrivileged: boolean;
  currentDocumentPrivilegeWarningsIgnored: boolean;
  onClose: () => void;
  setDisplayPrivilegeBanner: (x: boolean) => void;
  setCurrentDocumentIsPrivileged: (x: boolean) => void;
  setDisplayPrivilegeConfirmation: (x: boolean) => void;
  setCurrentDocumentIgnorePrivilegeWarnings: (x: boolean) => void;
}

export const FormPrivilegeConfigModal = ({
  formId,
  isOpen,
  currentDocumentIsPrivileged,
  currentDocumentPrivilegeWarningsIgnored,
  onClose,
  setDisplayPrivilegeBanner,
  setCurrentDocumentIsPrivileged,
  setDisplayPrivilegeConfirmation,
  setCurrentDocumentIgnorePrivilegeWarnings,
}: FormPrivilegeConfigModalProps) => {
  const [updateFormPrivilege] = useUpdateFormPrivilegeMutation();

  const modalWidth = "600px";

  const storePrivilegeState = React.useCallback(
    async (newPrivilegeState: boolean) => {
      try {
        const response = await updateFormPrivilege({
          formId,
          privileged: newPrivilegeState,
        }).unwrap();
        if (response) {
          setDisplayPrivilegeBanner(newPrivilegeState);
          setCurrentDocumentIsPrivileged(newPrivilegeState);
          setDisplayPrivilegeConfirmation(newPrivilegeState);
          if (newPrivilegeState) {
            setCurrentDocumentIgnorePrivilegeWarnings(false);
          }
          return true;
        }
        // TODO error modal
        return false;
      } catch (e) {
        // TODO error modal
        return false;
      }
    },
    [
      formId,
      setCurrentDocumentIgnorePrivilegeWarnings,
      setCurrentDocumentIsPrivileged,
      setDisplayPrivilegeBanner,
      setDisplayPrivilegeConfirmation,
      updateFormPrivilege,
    ]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={modalWidth}>
        <LuminosModalHeader
          title={"Legal Privilege Configuration"}
          closeButton={false}
        />
        {currentDocumentIsPrivileged ? (
          <FormPrivilegeEnabledBody
            onClose={onClose}
            savePrivilegeState={storePrivilegeState}
          />
        ) : (
          <FormPrivilegeDisabledBody
            formId={formId}
            currentDocumentPrivilegeWarningsIgnored={
              currentDocumentPrivilegeWarningsIgnored
            }
            onClose={onClose}
            setCurrentDocumentIgnorePrivilegeWarnings={
              setCurrentDocumentIgnorePrivilegeWarnings
            }
            savePrivilegeState={storePrivilegeState}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
