import React from "react";
import {
  Text,
  Icon,
  Input,
  InputGroup,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import { useElementContext } from "src/hooks/useElementContext";
import { useAppDispatch, useAppSelector } from "src/store";
import { FormElementBuildViewProps, RichTextDelta } from "src/types/form.types";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { selectSelectedElement } from "src/store/selectors/selected-element.selectors";
import {
  updateFormElement,
  toggleIsRulesDrawerOpen,
} from "src/store/slices/form.slice";
import { DeniedPreview } from "./DeniedPreview";
import { RichTextEditor } from "./RichTextEditor";

export const DeniedBuildView = ({ id }: FormElementBuildViewProps) => {
  const selectedElementId = useAppSelector(selectSelectedElement);
  const { element: elementState } = useElementContext();
  const element = useAppSelector(lookupFormElementById(id));
  const dispatch = useAppDispatch();

  if (element && element.type !== "denied") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const handleTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (element) {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              title: e.target.value,
            },
          })
        );
      }
    },
    [dispatch, element]
  );

  const handleDescriptionChange = React.useCallback(
    (description: RichTextDelta) => {
      if (element) {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              description,
            },
          })
        );
      }
    },
    [dispatch, element]
  );

  const handleRulesIconClick = React.useCallback(() => {
    dispatch(toggleIsRulesDrawerOpen(null));
  }, [dispatch]);

  const isSelectedElement = selectedElementId === id;

  if (!element) {
    return null;
  }

  return isSelectedElement ? (
    <div>
      <HStack justifyContent={"space-between"}>
        <InputGroup
          style={{
            marginLeft: `${(elementState?.indentationLevel || 0 - 1) * 20}px`,
          }}
          width="fit-content"
        >
          <Input
            htmlSize={element.content.title.length}
            placeholder={"Untitled"}
            onChange={handleTitleChange}
            style={{
              fontWeight: "bold",
            }}
            variant="unstyled"
            value={element.content.title}
          />
        </InputGroup>
        <HStack>
          {element?.rules && element?.rules.length > 0 ? (
            <Tooltip label="A rule is applied to this element?. Click to view details.">
              <Icon
                onClick={handleRulesIconClick}
                boxSize={5}
                as={RuleFolderOutlinedIcon}
              />
            </Tooltip>
          ) : null}
        </HStack>
      </HStack>
      <Text>
        When form rules cause this element to be displayed, the submission will
        be denied.
      </Text>
      <Text>Denial Text</Text>
      {/* <RichText
        simple={true}
        value={element.content.description}
        onChange={handleDescriptionChange}
      /> */}
      <RichTextEditor
        value={element.content.description}
        onChange={handleDescriptionChange}
      />
    </div>
  ) : (
    <DeniedPreview id={id} />
  );
};
