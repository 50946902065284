import { CommentThread, SlateObject, Comment as CommentType } from "../types";
import {
  Text,
  HStack,
  VStack,
  Avatar,
  Icon,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { NewCommentInput } from "./NewCommentInput";
import CheckIcon from "@mui/icons-material/Check";
import RichText from "./RichText";
import { Colors } from "../theme/colors";
import { Spacer, SpacerSizes } from "./Spacer";
import { nanoid } from "nanoid";

interface IConversation {
  commentThread: CommentThread;
  onResolve: () => void;
  onSend: (
    newCommentText: SlateObject,
    commentThread: CommentThread,
    assignedTo?: string[],
    mentioned?: string[]
  ) => void;
}

const PendingBadge = () => {
  return (
    <Tooltip label="Pending approval from document owner.">
      <Badge
        style={{ cursor: "pointer" }}
        variant="solid"
        color={"white.500"}
        colorScheme="yellow"
      >
        Pending
      </Badge>
    </Tooltip>
  );
};

const Comment = ({
  commentThread,
  comment,
  index,
  onResolve,
}: {
  commentThread: CommentThread;
  comment: CommentType;
  index: number;
  onResolve: (commentThread: CommentThread) => void;
}) => {
  const createdAt = new Date(comment.createdAt);
  const key = nanoid();

  return (
    <VStack alignItems="flex-start" gap={0} mb={4}>
      <HStack w={"100%"} alignItems="flex-start" justify="space-between">
        <HStack>
          <Avatar
            bg={"brightblue.100"}
            size="sm"
            key={index}
            name={comment.userEmail}
          />
          <VStack alignItems={"flex-start"} gap={0}>
            <Text variant="bold">
              {comment.firstName && comment.lastName
                ? [comment.firstName, comment.lastName].join(" ")
                : comment.userEmail}
            </Text>
            <Text variant="caption">
              {createdAt.toDateString()} {createdAt.toLocaleTimeString()}
            </Text>
          </VStack>
        </HStack>
        {index == 0 ? (
          <>
            {commentThread.resolved ? (
              <Text
                onClick={() => onResolve(commentThread)}
                mr={1}
                cursor="pointer"
                variant="caption"
              >
                Re-open
              </Text>
            ) : (
              <Tooltip label={"Resolve conversation"}>
                <Icon
                  data-test-id="resolve-conversation-btn"
                  onClick={() => onResolve(commentThread)}
                  justifySelf={"flex-end"}
                  style={{ cursor: "pointer" }}
                  as={CheckIcon}
                  boxSize={5}
                  color={"lightgray.600"}
                />
              </Tooltip>
            )}
          </>
        ) : null}
      </HStack>
      <HStack justifyItems={"flex-start"}>
        <RichText
          key={key}
          includeMentions={true}
          className="p-0 px-2"
          borderColor="border-none"
          value={comment.content}
          readOnly={true}
          showToolbar={false}
          size={"fit"}
        />

        {comment.pending ? <PendingBadge /> : null}
      </HStack>
    </VStack>
  );
};

export const Conversation = ({
  commentThread,
  onResolve,
  onSend,
}: IConversation) => {
  const assignedTo = commentThread.comments
    .flatMap((comment) => comment.assignedTo)
    .filter((x) => x);
  return (
    <div
      style={{
        marginBottom: 20,
        padding: 5,
        borderRadius: 2,
        backgroundColor: Colors.gray[50],
      }}
    >
      {commentThread.comments.map((comment: CommentType, i: number) => (
        <Comment
          index={i}
          key={i}
          comment={comment}
          commentThread={commentThread}
          onResolve={onResolve}
        />
      ))}
      <NewCommentInput
        onSend={(newCommentText, assignedTo, mentioned) =>
          onSend(newCommentText, commentThread, assignedTo, mentioned)
        }
      />
      {assignedTo.length ? (
        <div>
          <Spacer y={SpacerSizes.sm} />
          <Text fontWeight={"bold"} variant="caption">
            Resolution of conversation assigned to: {assignedTo.join(", ")}
          </Text>
        </div>
      ) : null}
    </div>
  );
};
