import { UnauthenticatedPage } from "../components/UnauthenticatedPage";
import { Heading, VStack, Text, Spacer } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export const PostLoginPage = () => {
  const { state } = useLocation();

  let displayWaitingList = false;
  let displayError = false;
  let displaySuccess = false;
  let displayDisabled = false;
  let displayUnknown = false;

  if (state && typeof state === "object") {
    if ("success" in state) {
      displaySuccess = true;
    } else if ("error" in state) {
      if (typeof state.error === "object" && "code" in state.error) {
        if (state.error.code === "UserIsWaitlisted") {
          displayWaitingList = true;
        } else if (state.error.code === "UserIsDisabled") {
          displayError = true;
          displayDisabled = true;
        } else {
          displayError = true;
          displayUnknown = true;
        }
      } else {
        displayError = true;
        displayUnknown = true;
      }
    } else {
      displayError = true;
      displayUnknown = true;
    }
  }

  return (
    <UnauthenticatedPage>
      <div
        style={{
          marginTop: "10%",
          paddingLeft: "25%",
          paddingRight: "25%",
        }}
      >
        {displayWaitingList ? <Heading>Thanks</Heading> : <></>}
        {displayError ? <Heading>Error</Heading> : <></>}
        {displaySuccess ? <Heading>Success</Heading> : <></>}
        <VStack spacing="0px" mt="16px">
          <Spacer mr="auto" />
          {displayWaitingList ? (
            <Text>
              We appreciate your interest and have added you to our waiting
              list. We&apos;ll be in touch.
            </Text>
          ) : (
            <></>
          )}
          {displayUnknown ? <Text>An unknown error occurred.</Text> : <></>}
          {displayDisabled ? <Text>Account disabled.</Text> : <></>}
          {displaySuccess ? <Text>{state.message}</Text> : <></>}
        </VStack>
      </div>
    </UnauthenticatedPage>
  );
};
