import { FormUser, MentionableUser } from "src/types";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { findUserIdsToAdd } from "src/util/comments.utils";
import { RootState } from "src/store";
import { newConversation, updateConversation } from "../slices/comments.slice";
import { formsApi } from "src/services/forms.service";
import { submissionsApi } from "src/services/submission.service";
import { SubmissionUser } from "src/types/submission.types";
import { organizationApi } from "src/services/organization.service";
import { MembershipType } from "src/types";
import { setSuccessText } from "../slices/snackbar-text.slice";

export const organizationMiddleware = createListenerMiddleware();

let lastNewConversationRequestAbort: () => void;

organizationMiddleware.startListening({
  matcher: isAnyOf(newConversation, updateConversation),
  effect: async (
    action: {
      payload: {
        assignedTo?: string[];
        mentioned?: string[];
        mentionableMembers: MentionableUser[];
      };
    },
    listener
  ) => {
    if (lastNewConversationRequestAbort) {
      lastNewConversationRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;
    const submissionState = state.submission;

    const { assignedTo, mentioned, mentionableMembers } = action.payload;

    const combinedMentionedAssigned = [
      ...(assignedTo ?? []),
      ...(mentioned ?? []),
    ];

    // invite users to organization
    const userEmailsToInvite = combinedMentionedAssigned.filter(
      (user) =>
        mentionableMembers.findIndex(
          (member: MentionableUser) =>
            member.data.email === user || member.text === user
        ) === -1
    );

    const newUserIds: string[] = [];
    if (userEmailsToInvite.length > 0) {
      for (const email of userEmailsToInvite) {
        const userInfo = {
          email: email,
          firstName: "",
          lastName: "",
          role: "guest",
          orgMembership: {
            membershipType: "guest" as MembershipType,
            permissions: {
              inviteUser: true,
              manageOrgPermissions: false,
              manageOrgSettings: false,
              manageLegalPrivilege: false,
              createOrCopyForms: false,
              seeAllMembers: false,
              seeAllGuests: false,
            },
          },
        };
        const res = await listener.dispatch(
          organizationApi.endpoints.inviteUserToOrganization.initiate({
            userInfo,
          })
        );
        if (
          res.data &&
          res.data.message === "Membership successfully created."
        ) {
          listener.dispatch(
            setSuccessText("The user was successfully invited.")
          );
          newUserIds.push(res.data.userId);
        }
      }
    }

    if (formState.status === "success") {
      const userIdsToAddToForm = findUserIdsToAdd(
        combinedMentionedAssigned,
        formState.form.users,
        mentionableMembers
      );

      const usersToAdd: FormUser[] = userIdsToAddToForm.concat(newUserIds).map(
        (user: string): FormUser => ({
          userId: user,
          role: "editor",
          isApproved: true,
        })
      );

      // add invited users to form
      if (usersToAdd.length) {
        const { abort } = listener.dispatch(
          formsApi.endpoints.addUsersToForm.initiate({
            formId: formState.form._id,
            users: usersToAdd,
          })
        );
        lastNewConversationRequestAbort = abort;
      }
    } else if (submissionState.status === "success") {
      const userIdsToAddToForm = findUserIdsToAdd(
        combinedMentionedAssigned,
        submissionState.submission.users,
        mentionableMembers
      );

      const usersToAdd: SubmissionUser[] = userIdsToAddToForm
        .concat(newUserIds)
        .map(
          (user: string): SubmissionUser => ({
            userId: user,
            role: "submitter",
            isApproved: true,
          })
        );

      // add invited users to form
      if (usersToAdd.length) {
        const { abort } = listener.dispatch(
          submissionsApi.endpoints.addUsersToSubmission.initiate({
            submissionId: submissionState.submission._id,
            users: usersToAdd,
          })
        );
        lastNewConversationRequestAbort = abort;
      }
    } else {
      throw new Error("Form or Submission not loaded trying to invite users");
    }
  },
});
