import { useAPIRequest } from "../hooks/useAPI";

export const useFileAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const postFiles = async (files: any) => {
    const response = await sendAPIRequest(`files`, "POST", files);
    return response.data;
  };

  const getFile = async (fileId: string) => {
    const response = await sendAPIRequest(`files/${fileId}`, "GET");
    return response.data;
  };

  const deleteFile = async (fileId: string) => {
    const response = await sendAPIRequest(`files/${fileId}`, "DELETE");
    return response.data;
  };

  return { postFiles, getFile, deleteFile };
};
