import {
  getAuthenticatedBaseQuery,
  transformDataResponse,
} from "src/util/base-query.utils";
import { UserQuery, User } from "../types";
import { createApi } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "@userApi",
  baseQuery: getAuthenticatedBaseQuery(`${process.env.REACT_APP_API_URL}`),
  endpoints: (builder) => ({
    getUser: builder.query<UserQuery, { targetOrganizationId: string | null }>({
      query: ({ targetOrganizationId }) => ({
        url: "/user",
        headers: {
          Organization: targetOrganizationId ?? undefined,
        },
      }),
      transformResponse: transformDataResponse<UserQuery>,
    }),

    getUserById: builder.query<{ user: User }, { userId: string }>({
      query: ({ userId }) => ({
        url: `/user/${userId}`,
      }),
      transformResponse: transformDataResponse<{ user: User }>,
    }),

    updateUser: builder.mutation<{ message: string }, { user: User }>({
      query: (body) => ({
        url: "/user",
        method: "PATCH",
        body,
      }),
      transformResponse: transformDataResponse<{ message: string }>,
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} = userApi;
