import {
  HStack,
  Flex,
  Icon,
  Text,
  Menu,
  MenuDivider,
  MenuItem,
  MenuButton,
  IconButton,
  MenuList,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";

import { AttentionItems, FormActions } from "../types";
import { MouseEventHandler } from "react";

export const tooltipOpenDelay = 1000;

export type FormsTableMenuListProps = {
  rowCallbacks: {
    copy(index: number): void;
    archive(index: number): void;
    unarchive(index: number): void;
    sharing(index: number): void;
    submission(index: number): void;
    sharingApproval(index: number): void;
  };
  rowsAllowedActions: Array<FormActions>;
  rowsAttentionItems: Array<AttentionItems>;
  onOpenInFormBuilder: (index: number) => void;
  archivePanel: boolean;
  index: number;
  hoverRowIndex: null | number;
};

export const FormsTableMenuList = ({
  index,
  rowsAllowedActions,
  rowsAttentionItems,
  hoverRowIndex,
  rowCallbacks,
  onOpenInFormBuilder,
  archivePanel,
}: FormsTableMenuListProps) => {
  const rowAllowedActions = rowsAllowedActions[index];
  const rowAttentionItems = rowsAttentionItems[index];
  const openAllowed = rowAllowedActions.edit || rowAllowedActions.comment;
  const displayPrivilegeApproval =
    rowAllowedActions.privilegeApprovals &&
    rowAttentionItems.privilegeApprovalsNeeded;
  const hIconSize = 5;
  const hIconColor = "gray.700";

  const handleOpenInFormBuilderClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    onOpenInFormBuilder(index);
  };

  return (
    <Flex width="100%">
      <Spacer />
      {hoverRowIndex === index ? (
        <HStack spacing="12px">
          {displayPrivilegeApproval ? (
            <Tooltip
              label="Approve Sharing Requests"
              aria-label="Approve sharing requests."
              openDelay={tooltipOpenDelay}
            >
              <Icon
                as={AdminPanelSettingsOutlinedIcon}
                boxSize={hIconSize}
                color={hIconColor}
                onClick={(event) => {
                  event.stopPropagation();
                  rowCallbacks.sharingApproval(index);
                }}
              />
            </Tooltip>
          ) : null}
          {rowAllowedActions.submit ? (
            <Tooltip
              label="Submit Form or Assign Submission"
              aria-label="Submit form or assign submission."
              openDelay={tooltipOpenDelay}
            >
              <div>
                <Icon
                  data-test-id="create-submission-from-form-icon-btn"
                  as={AssignmentIndOutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={(event) => {
                    event.stopPropagation();
                    rowCallbacks.submission(index);
                  }}
                />
              </div>
            </Tooltip>
          ) : null}
          {openAllowed ? (
            <Tooltip
              label="Open in Form Builder"
              aria-label="Open the form in the form builder."
              openDelay={tooltipOpenDelay}
            >
              <div>
                <Icon
                  data-test-id="open-form-icon-btn"
                  as={ConstructionOutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={handleOpenInFormBuilderClick}
                />
              </div>
            </Tooltip>
          ) : null}
          {rowAllowedActions.share ? (
            <Tooltip
              label="Sharing Settings"
              aria-label="Open sharing settings."
              openDelay={tooltipOpenDelay}
            >
              <Icon
                data-test-id="share-form-icon-btn"
                as={PersonAddAlt1OutlinedIcon}
                boxSize={hIconSize}
                color={hIconColor}
                onClick={(event) => {
                  event.stopPropagation();
                  rowCallbacks.sharing(index);
                }}
              />
            </Tooltip>
          ) : null}
        </HStack>
      ) : null}
      <Menu>
        <MenuButton
          data-test-id="form-menu-btn"
          as={IconButton}
          aria-label="Actions"
          variant="unstyled"
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "auto",
          }}
        >
          <Icon as={MoreVertOutlinedIcon} boxSize={4} />
        </MenuButton>
        <MenuList data-test-id="menu-list" mt="-10px">
          {displayPrivilegeApproval ? (
            <MenuItem
              key={`menu-priv-${index}`}
              data-test-id="copy-menu-item"
              onClick={(event) => {
                event.stopPropagation();
                rowCallbacks.sharingApproval(index);
              }}
            >
              <HStack>
                <Icon as={AdminPanelSettingsOutlinedIcon} boxSize={4} />
                <Text>Approve Sharing Requests</Text>
              </HStack>
            </MenuItem>
          ) : null}

          {rowAllowedActions.submit ? (
            <MenuItem
              key={`menu-submit-${index}`}
              data-test-id="create-submission-from-form-menu-item"
              onClick={(event) => {
                event.stopPropagation();
                rowCallbacks.submission(index);
              }}
            >
              <HStack>
                <Icon as={AssignmentIndOutlinedIcon} boxSize={4} />
                <Text>Submit Form or Assign Submission</Text>
              </HStack>
            </MenuItem>
          ) : null}
          {openAllowed ? (
            <MenuItem
              key={`menu-open-${index}`}
              data-test-id="open-form-menu-item"
              onClick={handleOpenInFormBuilderClick}
            >
              <HStack>
                <Icon as={ConstructionOutlinedIcon} boxSize={4} />
                <Text>Open in Form Builder</Text>
              </HStack>
            </MenuItem>
          ) : null}
          {rowAllowedActions.share ? (
            <MenuItem
              key={`menu-sharing-${index}`}
              data-test-id="share-menu-item"
              onClick={(event) => {
                event.stopPropagation();
                rowCallbacks.sharing(index);
              }}
            >
              <HStack>
                <Icon as={PersonAddAlt1OutlinedIcon} boxSize={4} />
                <Text>Sharing Settings</Text>
              </HStack>
            </MenuItem>
          ) : null}
          {rowAllowedActions.copy ? (
            <>
              <MenuDivider />
              <MenuItem
                key={`menu-copy-${index}`}
                data-test-id="copy-menu-item"
                onClick={(event) => {
                  event.stopPropagation();
                  rowCallbacks.copy(index);
                }}
              >
                <HStack>
                  <Icon as={ContentCopyOutlinedIcon} boxSize={4} />
                  <Text>Copy Form</Text>
                </HStack>
              </MenuItem>
            </>
          ) : null}

          {rowAllowedActions.archive && !archivePanel ? (
            <>
              <MenuDivider />
              <MenuItem
                key={`menu-archive-${index}`}
                data-test-id="archive-menu-item"
                onClick={(event) => {
                  event.stopPropagation();
                  rowCallbacks.archive(index);
                }}
              >
                <HStack>
                  <Icon as={ArchiveOutlinedIcon} boxSize={4} />
                  <Text>Archive Form</Text>
                </HStack>
              </MenuItem>
            </>
          ) : null}

          {rowAllowedActions.archive && archivePanel ? (
            <>
              <MenuDivider />
              <MenuItem
                key={`menu-unarchive-${index}`}
                data-test-id="unarchive-menu-item"
                onClick={(event) => {
                  event.stopPropagation();
                  rowCallbacks.unarchive(index);
                }}
              >
                <HStack>
                  <Icon as={UnarchiveOutlinedIcon} boxSize={4} />
                  <Text>Unarchive Form</Text>
                </HStack>
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Menu>
    </Flex>
  );
};
