import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";

export interface SnackBarTextState {
  successText: string | null;
  errorText: string | null;
}

const initialState: SnackBarTextState = {
  successText: null,
  errorText: null,
};

export const snackBarTextSlice = createSlice<
  SnackBarTextState,
  SliceCaseReducers<SnackBarTextState>,
  string,
  SliceSelectors<SnackBarTextState>,
  string
>({
  name: "snackbar-text",
  initialState,
  reducers: {
    setSuccessText: (state, action: PayloadAction<string | null>) => {
      state.successText = action.payload;
    },
    setErrorText: (state, action: PayloadAction<string | null>) => {
      state.errorText = action.payload;
    },
  },
});

export const { setSuccessText, setErrorText } = snackBarTextSlice.actions;
