import {
  Heading,
  HStack,
  Button,
  useDisclosure,
  Tab,
  Flex,
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  VStack,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { OrganizationMembershipModal } from "../components/Modal/OrganizationMembershipModal";
import SettingsTabPanel from "../components/SettingsTabPanel";
import AllowedDomainsTabPanel from "../components/AllowedDomainsTabPanel";
import MembersTabPanel from "../components/MembersTabPanel";
import PrivilegeTabPanel from "../components/PrivilegeTabPanel";
import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { useAppSelector } from "../store";

export const OrganizationPage = () => {
  const currentOrgMembership = useAppSelector(
    (state) => state.user.userContext?.currentOrgMembership
  );

  const {
    isOpen: orgMembershipModalOpen,
    onOpen: openOrgMembershipModal,
    onClose: closeOrgMembershipModal,
  } = useDisclosure();

  return (
    <AuthenticatedPage title="Manage Organization">
      <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
        {currentOrgMembership?.organization?.name ? (
          <OrganizationMembershipModal
            isOpen={orgMembershipModalOpen}
            onClose={closeOrgMembershipModal}
            membershipToEdit={null}
          />
        ) : null}
        <Flex flexDirection="row">
          <Heading variant="h2">
            {currentOrgMembership?.organization?.name}
          </Heading>
          <Spacer />
          {currentOrgMembership?.permissions.manageOrgPermissions ? (
            <HStack w="auto" ml="auto">
              <Button
                data-test-id="invite-org-member-button"
                onClick={openOrgMembershipModal}
              >
                Invite Member
              </Button>
            </HStack>
          ) : null}
        </Flex>
        <VStack align="left" pl="24px">
          <Spacer y={SpacerSizes.md} />
          <Tabs>
            <TabList>
              {currentOrgMembership?.permissions?.manageOrgSettings ? (
                <Tab data-test-id="org-tab-settings">Settings</Tab>
              ) : (
                <></>
              )}
              {currentOrgMembership?.permissions?.manageOrgSettings ? (
                <Tab data-test-id="org-tab-domains">Allowed Domains</Tab>
              ) : (
                <></>
              )}
              {currentOrgMembership?.permissions?.manageOrgPermissions ? (
                <Tab data-test-id="org-tab-members">Members</Tab>
              ) : (
                <></>
              )}
              {currentOrgMembership?.permissions?.manageLegalPrivilege ? (
                <Tab data-test-id="org-tab-privilege">Privilege Management</Tab>
              ) : (
                <></>
              )}
            </TabList>

            <TabPanels>
              {currentOrgMembership?.permissions?.manageOrgSettings ? (
                <TabPanel>
                  <SettingsTabPanel />
                </TabPanel>
              ) : null}
              {currentOrgMembership?.permissions?.manageOrgSettings ? (
                <TabPanel>
                  <AllowedDomainsTabPanel />
                </TabPanel>
              ) : null}
              {currentOrgMembership?.permissions?.manageOrgPermissions ? (
                <TabPanel>
                  <MembersTabPanel />
                </TabPanel>
              ) : null}

              {currentOrgMembership ? (
                <TabPanel>
                  <PrivilegeTabPanel />
                </TabPanel>
              ) : null}
            </TabPanels>
          </Tabs>
        </VStack>
      </div>
    </AuthenticatedPage>
  );
};
