import React from "react";
import {
  Alert,
  AlertProps,
  AlertTitle,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";

export interface ErrorSnackbarProps {
  title?: string;
  message: string | React.ReactNode | undefined;
  permanent?: boolean;
  duration?: number;
  severity?: AlertProps["severity"];
  onClose?: () => void;
}

export const SuccessSnackbar = ({
  title = "Success",
  message,
  permanent = false,
  duration = 5000,
  severity = "success",
  onClose,
}: ErrorSnackbarProps) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (message) {
      setShow(true);
    }
  }, [message]);

  const handleSnackbarClose = React.useCallback(
    (
      event: Event | React.SyntheticEvent<any, Event>,
      reason: SnackbarCloseReason
    ) => {
      if (reason === "clickaway") {
        return;
      }

      setShow(false);

      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Snackbar
      data-test-id="success-snackbar"
      open={show}
      autoHideDuration={permanent ? undefined : duration}
      onClose={handleSnackbarClose}
    >
      <Alert variant="filled" severity={severity}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};
