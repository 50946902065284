import { FormElement } from "../../types/form.types";
import { EnrichedForm, PathIndex, FormUser } from "../../types";
import {
  createSlice,
  isAnyOf,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import {
  findElementById,
  getElementsToBeUpdatedById,
  getFormElementsById,
  transformFormElementsToElementState,
} from "src/util/form.utils";
import { formsApi } from "src/services/forms.service";
import { addElementReducer } from "src/store/reducers/add-element.reducer";
import { indentElementReducer } from "../reducers/indent-element.reducer";
import { dedentElementReducer } from "../reducers/dedent-element.reducer";
import { deleteElementReducer } from "../reducers/delete-element.reducer";
import { swapElementReducer } from "../reducers/swap-element.reducer";
import { elementsByType } from "src/util/elements-by-type.utils";

export interface InitFormState {
  status: "init";
}

export interface ErrorFormState {
  status: "error";
  message: string;
}

export interface FormElementState {
  id: string;
  indentationLevel: number;
  pathIndex: PathIndex;
  titleNumberPathIndex: PathIndex;
  type: keyof typeof elementsByType;
  children: FormElementState[];
  parentElementId: string | null;
}

export interface LoadedFormState {
  status: "success";
  form: EnrichedForm;
  formId: string;

  elements: FormElementState[];

  elementsById: { [elementId: string]: FormElement };

  editingElementId: string | null;

  meta: EnrichedForm["meta"];
  displayPrivilegeBanner: boolean;
  displayPrivilegeConfirmation: boolean;
  isRulesDrawerOpen: boolean;
}

export interface SavingFormState extends Omit<LoadedFormState, "status"> {
  status: "saving";
}

export type FormState =
  | InitFormState
  | ErrorFormState
  | LoadedFormState
  | SavingFormState;

const initialState: FormState = {
  status: "init",
};

export const formSlice = createSlice<
  FormState,
  SliceCaseReducers<FormState>,
  string,
  SliceSelectors<FormState>,
  string
>({
  name: "form",
  initialState,
  reducers: {
    toggleIsRulesDrawerOpen: (state) => {
      if (state.status === "success" || state.status === "saving") {
        state.isRulesDrawerOpen = !state.isRulesDrawerOpen;
      }
    },
    setDisplayPrivilegeBanner: (state, action: PayloadAction<boolean>) => {
      if (state.status === "success" || state.status === "saving") {
        state.displayPrivilegeBanner = action.payload;
      }
    },

    setDisplayPrivilegeConfirmation: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.status === "success" || state.status === "saving") {
        state.displayPrivilegeConfirmation = action.payload;
      }
    },

    updateFormTitle: (state, action: PayloadAction<string>) => {
      if (state.status === "success" || state.status === "saving") {
        state.meta.title = action.payload;
      }
    },

    updateFormDescription: (state, action: PayloadAction<string>) => {
      if (state.status === "success" || state.status === "saving") {
        state.meta.description = action.payload;
      }
    },

    updateFormElement: (state, action: PayloadAction<FormElement>) => {
      if (state.status === "success" || state.status === "saving") {
        const updatedElement = action.payload;
        return {
          ...state,
          elementsById: {
            ...state.elementsById,
            [updatedElement.id]: updatedElement,
          },
        };
      }
    },

    setEditingElementId: (state, action: PayloadAction<string | null>) => {
      if (state.status === "success" || state.status === "saving") {
        return {
          ...state,
          editingElementId: action.payload,
        };
      }
    },

    updateFormUsers: (
      state,
      action: PayloadAction<{ documentUsers: FormUser[] }>
    ) => {
      if (state.status === "success" || state.status === "saving") {
        const { documentUsers } = action.payload;
        state.form.users = documentUsers;
      }
    },

    deleteFormUser: (state, action: PayloadAction<string>) => {
      if (state.status === "success" || state.status === "saving") {
        const userId = action.payload;
        state.form.users = state.form.users.filter(
          (user) => user.userId !== userId
        );
      }
    },

    addFormElement: addElementReducer,

    deleteFormElement: deleteElementReducer,

    swapFormElement: swapElementReducer,

    indentFormElement: indentElementReducer,

    dedentFormElement: dedentElementReducer,

    resetFormState: () => {
      return {
        status: "init",
      };
    },
  },

  extraReducers(builder) {
    builder.addMatcher(
      formsApi.endpoints.getFormById.matchFulfilled,
      (state, action) => {
        const form = action.payload;

        if (!form.elements) {
          return {
            status: "error",
            message: "Form does not contain elements",
          };
        }

        if (state.status === "success") {
          const elementsToBeUpdatedById = getElementsToBeUpdatedById({
            localElementsById: state.elementsById,
            serverElements: form.elements,
            editingElementId: state.editingElementId,
          });

          return {
            ...state,
            status: "success",
            form,
            formId: form._id,
            elements: transformFormElementsToElementState(form.elements),
            elementsById: {
              ...state.elementsById,
              ...elementsToBeUpdatedById,
            },
            meta: form.meta,
            editingElementId: state.editingElementId,
          };
        } else if (state.status === "init") {
          return {
            status: "success",
            form,
            formId: form._id,
            elements: transformFormElementsToElementState(form.elements),
            elementsById: getFormElementsById(form.elements),
            meta: form.meta,
            editingElementId: null,
            displayPrivilegeBanner: form.meta.privileged,
            displayPrivilegeConfirmation: form.meta.privileged,
            isRulesDrawerOpen: false,
          };
        }
      }
    );

    builder.addMatcher(
      isAnyOf(
        formsApi.endpoints.updateFormElement.matchPending,
        formsApi.endpoints.updateFormTitle.matchPending,
        formsApi.endpoints.updateFormDescription.matchPending
      ),
      (state: FormState) => {
        if (state.status !== "success" && state.status !== "saving") {
          throw new Error("Attempted to PATCH form when it was not loaded");
        }
        state.status = "saving";
      }
    );

    builder.addMatcher(
      isAnyOf(
        formsApi.endpoints.updateFormTitle.matchFulfilled,
        formsApi.endpoints.updateFormDescription.matchFulfilled
      ),
      (state: FormState) => {
        if (state.status !== "saving") {
          return;
        }
        return {
          ...state,
          status: "success" as const,
        };
      }
    );

    builder.addMatcher(
      formsApi.endpoints.updateFormElement.matchFulfilled,
      (state, action) => {
        const updatedForm = action.payload;
        const requestParams = action.meta.arg.originalArgs;
        if (state.status !== "success" && state.status !== "saving") {
          throw new Error("Attempted to PATCH form when it was not loaded");
        }
        const updatedElementsById: { [id: string]: FormElement } = {};
        const updatedParentElementById: { [id: string]: FormElement } = {};
        Object.keys(requestParams.updatedElementsById).forEach((id) => {
          const updatedElement = findElementById(id, updatedForm.elements);
          if (updatedElement) {
            updatedElementsById[id] = updatedElement;
            let parentElement = updatedElement.parentElementId
              ? state.elementsById[updatedElement.parentElementId]
              : null;

            if (parentElement) {
              const updatedParentChildren = parentElement.children.map((e) => {
                if (e.id !== updatedElement.id) {
                  return e;
                }
                return {
                  ...e,
                  ...updatedElement,
                };
              });
              parentElement = {
                ...parentElement,
                children: updatedParentChildren,
              };
              updatedParentElementById[parentElement.id] = parentElement;
            }
          }
        });
        return {
          ...state,
          status: "success",
          form: updatedForm,
          editingElementId: null,
          elementsById: {
            ...state.elementsById,
            ...updatedElementsById,
            ...updatedParentElementById,
          },
        };
      }
    );
  },
});

export const {
  setForm,
  toggleIsRulesDrawerOpen,
  setDisplayPrivilegeBanner,
  setDisplayPrivilegeConfirmation,
  setEditingElementId,
  updateFormElement,
  updateFormTitle,
  updateFormDescription,
  addFormElement,
  swapFormElement,
  deleteFormElement,
  indentFormElement,
  dedentFormElement,
  updateFormUsers,
  deleteFormUser,
  resetFormState,
} = formSlice.actions;
