import React, { useState } from "react";
import { Card, useToast, useDisclosure } from "@chakra-ui/react";
import { FormsTable } from "./FormsTable";
import { EnrichedFormContentless } from "src/types";
import { CreateSubmissionModal } from "src/components/Modal/CreateSubmissionModal";
import { useNavigate } from "react-router-dom";
import { SharingModal } from "src/components/Modal/SharingModal";
import { ApproveSharingModal } from "src/components/Modal/ApproveSharingModal";
import {
  useArchiveFormMutation,
  useCopyFormMutation,
  useGetFormsQuery,
} from "src/services/forms.service";

interface FormsSectionProps {
  forms: EnrichedFormContentless[];
  tableDataTestId?: string;
}
interface FormDataRow {
  title: string;
  description: string;
  updatedAt: Date | null;
}

export const ActiveFormsSection = ({
  tableDataTestId,
  forms,
}: FormsSectionProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [formIdForModal, setFormIdForModal] = useState("");
  const [formForModal, setFormForModal] = useState<EnrichedFormContentless>();
  const [becomeAdjudicatorForModal, setBecomeAdjudicatorForModal] =
    useState(false);
  const [formNameForModal, setFormNameForModal] = useState("");
  const {
    isOpen: submissionModalIsOpen,
    onOpen: openSubmissionModal,
    onClose: closeSubmissionModal,
  } = useDisclosure();
  const {
    isOpen: sharingModalIsOpen,
    onOpen: openSharingModal,
    onClose: closeSharingModal,
  } = useDisclosure();
  const {
    isOpen: sharingApprovalModalIsOpen,
    onOpen: openSharingApprovalModal,
    onClose: closeSharingApprovalModal,
  } = useDisclosure();
  const { refetch } = useGetFormsQuery({
    archived: false,
  });
  const [copyForm] = useCopyFormMutation();
  const [archiveForm] = useArchiveFormMutation();

  function truncate(inStr: string | null) {
    const str = inStr || "";
    const maxlen = 40;
    return str.length > maxlen ? str.slice(0, maxlen - 1) + "..." : str;
  }

  const headers = ["", "Title", "Description", "Last Updated", "Actions"];
  const rowsData: FormDataRow[] = forms.map((form: EnrichedFormContentless) => {
    return {
      title: truncate(form.meta.title),
      description: truncate(form.meta.description) as string,
      updatedAt: form.meta.updatedAt,
    };
  });

  const rowsAllowedActions = forms.map(
    (form: EnrichedFormContentless) => form.readOnly.actions
  );

  const rowsAttentionItems = forms.map(
    (form: EnrichedFormContentless) => form.readOnly.attentionItems
  );

  const rowClickHandlers = forms.map(
    (form: EnrichedFormContentless) => () =>
      form.readOnly.actions.edit
        ? navigate(`/form-builder/${form._id}`)
        : navigate(`/create-submission/${form._id}`)
  );

  const handleCopy = async (formIndex: number) => {
    const formId = forms[formIndex]._id;

    await copyForm({ formId });
    toast({
      title: "Form copied successfully.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleArchive = async (formIndex: number) => {
    const formId = forms[formIndex]._id;

    await archiveForm({ formId });
    toast({
      title: "Form archived successfully.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleSharingApproval = async (formIndex: number) => {
    const formId = forms[formIndex]._id;
    setFormIdForModal(formId);
    setFormForModal(forms[formIndex]);
    openSharingApprovalModal();
  };

  const handleCreateSubmission = async (formIndex: number) => {
    const formId = forms[formIndex]._id;
    setFormIdForModal(formId);
    setFormNameForModal(forms[formIndex].meta.title);
    setBecomeAdjudicatorForModal(
      rowsAllowedActions[formIndex].becomeAdjudicator
    );
    openSubmissionModal();
  };

  const handleSharing = async (formIndex: number) => {
    setFormForModal(forms[formIndex]);
    openSharingModal();
  };

  const rowCallbacks = {
    copy: (index: number) => handleCopy(index),
    archive: (index: number) => handleArchive(index),
    unarchive: () => {},
    sharing: (index: number) => handleSharing(index),
    submission: (index: number) => handleCreateSubmission(index),
    sharingApproval: (index: number) => handleSharingApproval(index),
  };

  const closeApprovalModal = React.useCallback(() => {
    closeSharingApprovalModal();
    refetch();
  }, [closeSharingApprovalModal, refetch]);

  const closeSharing = React.useCallback(() => {
    closeSharingModal();
    refetch();
  }, [closeSharingModal, refetch]);

  return (
    <>
      {submissionModalIsOpen ? (
        <CreateSubmissionModal
          isOpen={submissionModalIsOpen}
          onClose={closeSubmissionModal}
          formId={formIdForModal}
          formName={formNameForModal}
          becomeAdjudicator={becomeAdjudicatorForModal}
        />
      ) : null}
      {sharingApprovalModalIsOpen ? (
        <ApproveSharingModal
          document={formForModal}
          type="form"
          isOpen={sharingApprovalModalIsOpen}
          onClose={closeApprovalModal}
        />
      ) : null}
      {formForModal ? (
        <SharingModal
          document={formForModal}
          type="form"
          isOpen={sharingModalIsOpen}
          onClose={closeSharing}
        />
      ) : null}
      <Card variant="outline">
        <FormsTable
          dataTestId={tableDataTestId}
          headers={headers}
          rowsData={rowsData}
          rowsAllowedActions={rowsAllowedActions}
          rowsAttentionItems={rowsAttentionItems}
          rowCallbacks={rowCallbacks}
          rowClickHandlers={rowClickHandlers}
          archivePanel={false}
        />
      </Card>
    </>
  );
};
