import { extendTheme } from "@chakra-ui/react";
import { createTheme, THEME_ID } from "@mui/material/styles";

// Component style overrides
import { ButtonStyle } from "./components/button";
import { SpinnerStyle } from "./components/spinner";
import { HeadingStyle } from "./components/heading";
import { Colors } from "./colors";
import { TextStyle } from "./components/text";
import { IconStyle } from "./components/icon";

const overrides = {
  colors: Colors,
  fonts: {
    heading: "Lato Heavy",
    body: "Lato",
    label: "Lato",
  },
  components: {
    Button: ButtonStyle,
    Spinner: SpinnerStyle,
    Heading: HeadingStyle,
    Text: TextStyle,
    Icon: IconStyle,
    Checkbox: {
      parts: ["control"],
      baseStyle: {
        control: {
          _checked: {
            _disabled: {
              bg: "gray.100",
              color: "#9d9d9d",
            },
          },
        },
      },
    },
  },
};

const muiTheme = createTheme();
const chakraTheme = extendTheme(overrides);

export default { ...chakraTheme, [THEME_ID]: muiTheme };
