import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
  Text,
} from "@chakra-ui/react";
import { LuminosModalHeader } from "./LuminosModalHeader";
import { useNavigate } from "react-router-dom";
import { RootState, useAppSelector } from "../../store";

const currentDocumentCommunicationsTerms =
  "Information related to this project is strictly confidential. All communications related to this topic are to be handled through the Luminos system. Information related to this project is not to be shared with others outside of this system without explicit permission of counsel.";
const currentDocumentPrivilegeAgreement =
  "I have read and understood the Communication Terms and agree to abide by those terms.";

export const PrivilegeConfirmationModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (x: boolean) => void;
}) => {
  const navigate = useNavigate();
  const privilegeSettings = useAppSelector(
    (state: RootState) =>
      state.user.userContext?.currentOrgMembership?.organization
        ?.privilegeSettings
  );
  const privilegeAgreement = privilegeSettings?.privilegeAgreement;
  const privilegeTerms = privilegeSettings?.privilegeTerms;
  const modalWidth = "600px";

  function closePrivilegeAgreement() {
    setOpen(false);
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={open}
      onClose={closePrivilegeAgreement}
    >
      <ModalOverlay
        bg="blackAlpha.400"
        backdropFilter="blur(20px)
          hue-rotate(90deg)"
      />
      <ModalContent minWidth={modalWidth}>
        <LuminosModalHeader
          title={"Legal Privilege Confirmation"}
          closeButton={false}
        />
        <ModalBody bg="white">
          <VStack alignItems={"flex-start"} spacing="32px" mt="8px">
            <Alert status="info" variant="left-accent">
              Document access requires agreement.
            </Alert>
            <Text>{privilegeTerms ?? currentDocumentCommunicationsTerms}</Text>
            <Text>
              {privilegeAgreement ?? currentDocumentPrivilegeAgreement}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter bg="white">
          <Button variant="secondary" onClick={() => navigate("/")}>
            Decline
          </Button>
          <Button
            data-test-id="privilege-confirmation-agree-button"
            onClick={closePrivilegeAgreement}
          >
            Agree
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
