import { useState, useEffect } from "react";
import { Flex, HStack, Icon, Text, VStack, Divider } from "@chakra-ui/react";
import { Colors } from "../theme/colors";
import { Spacer, SpacerSizes } from "../components/Spacer";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

export const SUBMISSION_STATUS_HEIGHT = "45px";

export const SubmissionStatusDisplay = ({
  submissionStatus,
}: {
  submissionStatus: string;
}) => {
  const [documentProgress, setDocumentProgress] = useState("In Progress");

  useEffect(() => {
    if (submissionStatus) {
      setDocumentProgress(submissionStatus);
    }
  }, [submissionStatus]);

  const StatusDisplay = () => {
    return (
      <Flex
        data-test-id={`status-display-${documentProgress}`}
        border={`1px solid ${Colors.gray[600]}`}
        w="20vw"
        pt="8px"
        pb="8px"
        borderRadius="3px"
        bg="white"
        mx={3}
        alignContent="flex-start"
        borderLeftColor={
          documentProgress === "approved" ? "green.500" : "red.500"
        }
        borderRightColor={"gray.500"}
        borderLeftWidth={7}
        justifyContent={"space-between"}
      >
        <Flex w={"80%"} flexDirection="row" alignItems={"center"}>
          <Spacer x={SpacerSizes.sm} />
          {documentProgress === "denied" ? (
            <Icon color="gray.400" as={RemoveCircleOutlineOutlinedIcon} />
          ) : (
            <span
              style={{ color: "#8F8F8F" }}
              className="material-symbols-outlined"
            >
              new_releases
            </span>
          )}
          <Spacer x={SpacerSizes.sm} />
          <Text variant="bold">
            {documentProgress.charAt(0).toUpperCase() +
              documentProgress.slice(1)}
          </Text>
        </Flex>
        <Divider borderColor="gray.500" orientation="vertical" />
      </Flex>
    );
  };

  return (
    <div
      style={{
        height: SUBMISSION_STATUS_HEIGHT,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <HStack w="100%">
        {!["approved", "denied"].includes(documentProgress) ? null : (
          <VStack>
            <StatusDisplay />
          </VStack>
        )}
      </HStack>
    </div>
  );
};
