import { Flex, Icon, Wrap, WrapItem, Text, Divider } from "@chakra-ui/react";
import { TbSectionSign } from "react-icons/tb";
import { Colors } from "../theme/colors";
import {
  AttachFile,
  Approval,
  EditNote,
  ViewHeadline,
  Checklist,
  Check,
} from "@mui/icons-material";
import {
  getEmptyTextResponse,
  getEmptyApproved,
  getEmptyAttestation,
  getEmptyBiasElement,
  getEmptyDenied,
  getEmptyFileUpload,
  getEmptyMultiSelect,
  getEmptySection,
  getEmptySingleSelect,
  getEmptyTextBlock,
} from "../util/element.utils";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { FormElement } from "src/types/form.types";
import { useAppDispatch } from "src/store";
import { addFormElement } from "src/store/slices/form.slice";
import React from "react";

interface IOption {
  dataTestId: string;
  icon: JSX.Element;
  title: string;
  elementFn: (parentElementId: string) => FormElement;
}

export default function SelectStaticComponent({
  onClose,
  elementId,
}: {
  onClose: () => void;
  isSection: boolean;
  elementId: string;
}) {
  const dispatch = useAppDispatch();
  const surveyOptions: IOption[] = [
    {
      dataTestId: "text-response-element-item",
      icon: <Icon as={EditNote} />,
      title: "Text Response",
      elementFn: getEmptyTextResponse,
    },
    {
      dataTestId: "multi-select-element-item",
      icon: <Icon as={Checklist} />,
      title: "Multi Select",
      elementFn: getEmptyMultiSelect,
    },
    {
      dataTestId: "single-select-element-item",
      icon: <Icon as={Check} />,
      title: "Single Select",
      elementFn: getEmptySingleSelect,
    },
    {
      dataTestId: "file-upload-element-item",
      icon: <Icon as={AttachFile} />,
      title: "File Attachment",
      elementFn: getEmptyFileUpload,
    },
    {
      dataTestId: "attestation-element-item",
      icon: <Icon as={Approval} />,
      title: "Attestation",
      elementFn: getEmptyAttestation,
    },
  ];

  const staticOptions: IOption[] = [
    {
      dataTestId: "section-element-item",
      icon: <Icon boxSize={6} as={TbSectionSign} />,
      title: "Section",
      elementFn: getEmptySection,
    },
    {
      dataTestId: "text-block-element-item",
      icon: <Icon as={ViewHeadline} />,
      title: "Text Block",
      elementFn: getEmptyTextBlock,
    },
  ];

  const variableOptions: IOption[] = [
    {
      dataTestId: "bias-analysis-element-item",
      icon: <span className="material-symbols-outlined">search_insights</span>,
      title: "Bias Analysis",
      elementFn: getEmptyBiasElement,
    },
  ];

  const endStateOptions: IOption[] = [
    {
      dataTestId: "approved-element-item",
      icon: (
        <span
          style={{ color: "#3C3C3C" }}
          className="material-symbols-outlined"
        >
          new_releases
        </span>
      ),
      title: "Approved",
      elementFn: getEmptyApproved,
    },
    {
      dataTestId: "denied-element-item",
      icon: <Icon as={RemoveCircleOutlineOutlinedIcon} />,
      title: "Denied",
      elementFn: getEmptyDenied,
    },
  ];

  const addSectionBelow = React.useCallback(
    (option: IOption) => {
      return () => {
        const emptyElement = option.elementFn(elementId);
        onClose();
        dispatch(
          addFormElement({
            referenceElementId: elementId,
            element: emptyElement,
          })
        );
      };
    },
    [dispatch, elementId, onClose]
  );

  const OptionCard = ({ option }: { option: IOption }) => (
    <Flex
      onClick={addSectionBelow(option)}
      _hover={{ cursor: "pointer", backgroundColor: Colors.gray[50] }}
      style={{
        width: 200,
        flexDirection: "row",
      }}
      align={"center"}
      gap={4}
      py={2}
      px={1}
    >
      {option.icon}
      <Text data-test-id={option.dataTestId} variant="h2">
        {option.title}
      </Text>
    </Flex>
  );

  return (
    <div>
      <Flex w={"100%"} justifyContent={"flex-end"}>
        <Icon onClick={onClose} as={CloseIcon} />
      </Flex>
      <Flex
        contentEditable={false}
        style={{ userSelect: "none" }}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Wrap>
          {staticOptions.map((option, index) => (
            <WrapItem key={index}>
              <div className="panel" key={index}>
                <OptionCard option={option} />
              </div>
            </WrapItem>
          ))}
          <Divider />
          {surveyOptions.map((option, index) => (
            <WrapItem key={index}>
              <div className="panel" key={index}>
                <OptionCard option={option} />
              </div>
            </WrapItem>
          ))}
          <Divider />
          {variableOptions.map((option, index) => (
            <WrapItem key={index}>
              <div className="panel" key={index}>
                <OptionCard option={option} />
              </div>
            </WrapItem>
          ))}
          <Divider />
          {endStateOptions.map((option, index) => (
            <WrapItem key={index}>
              <div className="panel" key={index}>
                <OptionCard option={option} />
              </div>
            </WrapItem>
          ))}
        </Wrap>
      </Flex>
    </div>
  );
}
