import React from "react";
import * as Sentry from "@sentry/react";
import { resetAuthState, setSessionAndToken } from "../store/slices/auth.slice";
import { supabaseClient } from "../clients/supabase.client";
import { useAppDispatch } from "../store";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { resetOrganizationState } from "src/store/slices/organization.slice";
import { clearPrivilegeFromStorage } from "src/util/privilege.utils";

export const useAuthenticatedSession = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    /**
     * Handle any supabase session changes
     */
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((event, session) => {
      // event includes `TOKEN_REFRESHED` which refreshes the session/token
      if (event === "INITIAL_SESSION" || event === "SIGNED_IN") {
        return;
      }

      if (session && session.access_token) {
        localStorage.setItem("token", session.access_token);
        dispatch(setSessionAndToken({ token: session.access_token, session }));
      } else if (!session) {
        clearPrivilegeFromStorage();
        localStorage.removeItem("token");
        dispatch(resetAuthState());
        dispatch(resetOrganizationState());
        navigate(routes.Login.path);
      } else {
        Sentry.captureMessage("Unexpected session response", {
          extra: {
            event,
            session,
          },
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch, navigate]);
};
