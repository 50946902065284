import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { MembershipDetail } from "src/types/organization.types";
import { useUpdateOrganizationMembershipMutation } from "src/services/organization.service";
import {
  ModalFooter,
  ModalBody,
  Text,
  Button,
  VStack,
  Box,
  Icon,
} from "@chakra-ui/react";
import { LuminosModalHeader } from "./LuminosModalHeader";
import { OrganizationModalUserExpiration } from "./OrganizationModalUserExpiration";
import {
  determinePermissionsFromOrgRole,
  determineOrgRoleTemplate,
} from "../../util/organization.utils";
import { OrganizationModalNewUserRole } from "./OrganizationModalNewUserRole";
import { Spacer, SpacerSizes } from "../Spacer";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectUserState } from "src/store/selectors/users.selector";
import { OrganizationModalPermissionDetail } from "./OrganizationModalPermissionDetail";
import {
  permissionDetails,
  PermissionDetail,
} from "./OrganizationMembershipModal";
import {
  selectOrganizationMemberInfo,
  selectOrganizationMembershipEdited,
  selectOrganizationMembershipExpiration,
  selectOrganizationMembershipShowCustom,
  selectCurrentOrgMembership,
  selectOrgRoleName,
} from "src/store/selectors/organization-membership.selectors";
import {
  setCurrentOrgMembershipField,
  setExpirationDate,
  setExpirationEnabled,
  setExpirationNowVsScheduled,
  setOrgPermissions,
  setShowCustom,
  setUserInfo,
  setOrgRole,
  orgRoleTemplateNames,
} from "src/store/slices/organization-membership.slice";
import { UserQuery } from "src/types";
import { setSuccessText } from "src/store/slices/snackbar-text.slice";
import { setErrorText } from "src/store/slices/snackbar-text.slice";

export const EditOrganizationMembership = ({
  membershipToEdit,
  onClose,
}: {
  membershipToEdit: MembershipDetail;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [updateOrganizationMembership] =
    useUpdateOrganizationMembershipMutation();

  const userInfo = useAppSelector(selectOrganizationMemberInfo);
  const expiration = useAppSelector(selectOrganizationMembershipExpiration);
  const membershipEdited = useAppSelector(selectOrganizationMembershipEdited);
  const showCustom = useAppSelector(selectOrganizationMembershipShowCustom);
  const currentOrgMembership = useAppSelector(selectCurrentOrgMembership);
  const orgRole = useAppSelector(selectOrgRoleName);

  const organizationName =
    useAppSelector(selectUserState).userContext?.currentOrgMembership.name;

  async function addUser() {
    const orgMembership = { ...currentOrgMembership };
    if (expiration.expirationEnabled) {
      if (expiration.nowVsScheduled === "immediately") {
        orgMembership.membershipExpiration = moment().toISOString();
      } else {
        orgMembership.membershipExpiration = expiration.expirationDateTime;
      }
    }

    const requestBody = {
      userInfo: {
        firstName: userInfo.user.firstName ?? "",
        lastName: userInfo.user.lastName ?? "",
        email: userInfo.user.email,
        orgMembership,
      },
    };

    try {
      await updateOrganizationMembership(requestBody).unwrap();
      dispatch(setSuccessText("Membership updated successfully"));
      onClose();
    } catch {
      dispatch(
        setErrorText("An error occured while trying to edit the membership.")
      );
    }
  }

  const changeRoleTemplate = React.useCallback(
    (userType: string, isMember: boolean, newRole?: string) => {
      const permissions = determinePermissionsFromOrgRole(
        newRole ?? orgRole,
        userInfo,
        isMember
      );
      dispatch(setOrgPermissions(permissions));
      dispatch(
        setCurrentOrgMembershipField({
          value: userType,
          field: "membershipType",
        })
      );
      if (newRole) {
        dispatch(setOrgRole(newRole));
      }
      if (orgRoleTemplateNames.custom === newRole) {
        dispatch(setShowCustom(true));
      } else {
        dispatch(setShowCustom(false));
      }
    },
    [userInfo, dispatch, orgRole]
  );

  const handleRelationshipChangetoMember = React.useCallback(() => {
    changeRoleTemplate("member", true);
  }, [changeRoleTemplate]);

  const handleRelationshipChangetoGuest = React.useCallback(() => {
    changeRoleTemplate("guest", false);
  }, [changeRoleTemplate]);

  const changeRoleTemplateWrapper = React.useCallback(
    (newRole: string) => {
      changeRoleTemplate(
        userInfo.orgMemberships[0].membershipType,
        userInfo.orgMemberships[0].membershipType == "member",
        newRole
      );
    },
    [changeRoleTemplate, userInfo]
  );

  React.useEffect(() => {
    const organization = {
      _id: membershipToEdit._id,
      userId: membershipToEdit.userId,
      organizationId: membershipToEdit.organizationId,
      membershipType: membershipToEdit.membershipType,
      permissions: {
        ...membershipToEdit.permissions,
      },
    };

    const defaultUser = {
      user: {
        email: membershipToEdit.user.email,
        firstName: membershipToEdit.user.firstName,
        lastName: membershipToEdit.user.lastName,
        accountStatus: "pending",
        _id: "",
        supabaseId: "",
      },
      currentOrgMembership: organization,
      orgMemberships: [organization],
    } as UserQuery;

    dispatch(setUserInfo(defaultUser));
    dispatch(setOrgRole(determineOrgRoleTemplate(membershipToEdit)));
    if (membershipToEdit?.membershipExpiration) {
      dispatch(setExpirationDate({ e: membershipToEdit.membershipExpiration }));
      dispatch(setExpirationEnabled({ enabled: true }));
      dispatch(setExpirationNowVsScheduled("at a scheduled date and time"));
    }
  }, [membershipToEdit, dispatch]);

  return (
    <>
      <LuminosModalHeader
        iconWrapper={<Icon as={EditOutlinedIcon} ml="4px"></Icon>}
        title={`Edit ${membershipToEdit.user.email}'s Membership`}
        closeButton={false}
      />

      <ModalBody bg="white">
        <VStack
          spacing="0px"
          mt="8px"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <OrganizationModalNewUserRole
            userInfo={userInfo}
            membershipToEdit={membershipToEdit}
            handleRelationshipChangetoMember={handleRelationshipChangetoMember}
            handleRelationshipChangetoGuest={handleRelationshipChangetoGuest}
            changeRoleTemplateWrapper={changeRoleTemplateWrapper}
            organizationName={organizationName ?? ""}
          />

          <Box h="1px" w="auto" bg="gray.500"></Box>
          <Spacer y={SpacerSizes.md} />
          <VStack>
            <VStack spacing={2} align="left">
              <Text>Permission Detail</Text>
              {permissionDetails.map((p: PermissionDetail, i: number) => (
                <OrganizationModalPermissionDetail
                  showCustom={showCustom}
                  permission={p}
                  key={i}
                />
              ))}
            </VStack>
          </VStack>
          <Spacer y={SpacerSizes.md} />
          <OrganizationModalUserExpiration
            membershipToEdit={membershipToEdit}
          />
        </VStack>
      </ModalBody>
      <ModalFooter bg="white">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          disabled={!membershipEdited}
          data-test-id="send-invite-button"
          onClick={addUser}
        >
          Save Edits
        </Button>
      </ModalFooter>
    </>
  );
};
