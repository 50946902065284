import React from "react";
import { useGetSubmissionByIdQuery } from "src/services/submission.service";
import { AuthenticatedPage } from "./AuthenticatedPage";
import { PageLoader } from "src/lib/components/PageLoader";
import { SubmissionEditor } from "./SubmissionEditor";
import { Box, Typography } from "@mui/material";
import { selectSubmissionEditingElementId } from "src/store/selectors/submission.selectors";
import { useAppDispatch, useAppSelector } from "src/store";
import { setErrorText } from "src/store/slices/snackbar-text.slice";

interface SubmissionEditorDataPageProps {
  submissionId: string;
}

export const SubmissionEditorDataPage = ({
  submissionId,
}: SubmissionEditorDataPageProps) => {
  const editingElementId = useAppSelector(selectSubmissionEditingElementId);
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useGetSubmissionByIdQuery(
    {
      submissionId,
    },
    {
      pollingInterval: 5000,

      /**
       * We want to skip polling when a user is editing an element.
       * Adding a `editingElementId` is the best way to check if an element is being edited.
       */
      skip: Boolean(editingElementId),
    }
  );

  React.useEffect(() => {
    if (error) {
      dispatch(setErrorText("Failed to fetch submission"));
    }
  }, [error, dispatch]);

  return (
    <AuthenticatedPage>
      {isLoading && !error ? <PageLoader /> : null}

      {error && !isLoading ? (
        <Box>
          <Typography variant="h3">Document Not Found</Typography>
        </Box>
      ) : null}

      {data && !isLoading && !error ? <SubmissionEditor /> : null}
    </AuthenticatedPage>
  );
};
