import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { supabaseClient } from "src/clients/supabase.client";
import { RootState } from "src/store";
import { resetAuthState } from "src/store/slices/auth.slice";
import { resetOrganizationState } from "src/store/slices/organization.slice";
import * as Sentry from "@sentry/react";
import { DataResponse } from "src/types";
import { isErrorResponse } from "./error.utils";

export const getAuthenticatedBaseQuery = (baseUrl: string) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;
      const orgId = state.user.currentOrganizationId;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      if (orgId) {
        headers.set("Organization", orgId);
      }
    },
  });
  const authenticatedBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const response = await baseQuery(args, api, extraOptions);
    const state = api.getState() as RootState;

    if (response.error && response.error.status === 401) {
      Sentry.captureMessage(
        "Received 401 Response - Redirecting to login page ",
        {
          level: "info",
          extra: {
            /**
             * capture api params and auth session details for debugging
             * session has expires_in and expires_at which can be helpful when comparing
             * the sentry log timestamp
             */
            args,
            session: state.auth.session,
          },
        }
      );
      localStorage.removeItem("token");
      api.dispatch(resetAuthState());
      api.dispatch(resetOrganizationState());
      supabaseClient.auth.signOut();
    }

    const isAbortError =
      response.error &&
      "error" in response.error &&
      response.error.error.includes("AbortError");

    if (
      response.error &&
      (typeof response.error.status === "string" ||
        response.error.status > 500) &&
      !isAbortError
    ) {
      Sentry.captureMessage("Failed request", {
        extra: {
          args,
          error: response.error,
        },
      });
    }

    if (!response.data) {
      throw new Error("No data returned from request");
    }
    return response;
  };

  return authenticatedBaseQuery;
};

export const transformDataResponse = <T>(response: DataResponse<T>) => {
  return response.data;
};

export const transformErrorResponse = (response: unknown) => {
  if (isErrorResponse(response)) {
    return response.data.error;
  }
  if (response && typeof response === "object" && "data" in response) {
    return response.data;
  }
  return response;
};
