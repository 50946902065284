import {
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

interface DemographicTableProps {
  headers: string[];
  demographicValues: any;
  handleDemographicChange: (
    category: string,
    label: string,
    categoryLabels: string[]
  ) => void;
  disabled: boolean;
  dataTestIdPrefix: string;
}

export const BiasAnalysisDemographicTable = ({
  headers,
  demographicValues,
  handleDemographicChange,
  disabled,
  dataTestIdPrefix,
}: DemographicTableProps) => {
  const handleCheckboxChange = (
    category: string,
    header: string,
    isChecked: boolean
  ) => {
    const categoryLabels = [...demographicValues[category].labels];
    if (isChecked) {
      // Add the header to the labels array if checked
      if (!categoryLabels.includes(header)) {
        categoryLabels.push(header);
      }
    } else {
      // Remove the header from the labels array if unchecked
      const index = categoryLabels.indexOf(header);
      if (index !== -1) {
        categoryLabels.splice(index, 1);
      }
    }
    // Call the onDemographicChange function with the updated labels array
    handleDemographicChange(category, "labels", categoryLabels);
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Table data-test-id={`${dataTestIdPrefix}-table`} variant="simple">
        <Thead>
          <Tr data-test-id={`${dataTestIdPrefix}-table-headers`}>
            <Th>Header</Th>
            {Object.keys(demographicValues).map((category) => (
              <Th key={category}>{category}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {headers.map((header) => (
            <Tr key={header}>
              <Td>{header}</Td>
              {Object.keys(demographicValues).map((category) => (
                <Td key={`${header}-${category}`}>
                  <Checkbox
                    data-test-id={`${dataTestIdPrefix}-${header}-${category.toLowerCase()}`}
                    isDisabled={disabled}
                    key={`${header}-${category}`}
                    isChecked={demographicValues[category].labels.includes(
                      header
                    )}
                    onChange={(e) =>
                      handleCheckboxChange(category, header, e.target.checked)
                    }
                  />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};
