import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { CommentThread, MentionableUser, UserQuery } from "src/types";

import { CommentsState } from "../slices/comments.slice";

export const newConversationReducer: CaseReducer<
  CommentsState,
  PayloadAction<{
    elementId: string;
    conversationType: string;
    mentionableMembers: MentionableUser[];
    commentText: string;
    globalUserInfo: UserQuery | null;
    commentThreads?: CommentThread[];
    assignedTo?: string[];
    mentioned?: string[];
  }>
> = (state, action) => {
  const {
    elementId,
    conversationType,
    commentText,
    globalUserInfo,
    assignedTo,
    mentioned,
  } = action.payload;

  if (!globalUserInfo) {
    throw new Error("Global user info is not set");
  }

  const newConversation: CommentThread = {
    type: conversationType,
    resolved: false,
    comments: [
      {
        createdAt: new Date().toISOString(),
        user: globalUserInfo.user._id,
        content: commentText,
        assignedTo: assignedTo,
        mentioned: mentioned,
        userEmail: globalUserInfo.user.email,
        firstName: globalUserInfo.user.firstName,
        lastName: globalUserInfo.user.lastName,
        pending: false,
      },
    ],
  };

  if (!state.commentThreadsDictionary) {
    state.commentThreadsDictionary = {};
  }

  if (!state.commentThreadsDictionary[elementId]) {
    state.commentThreadsDictionary[elementId] = [];
  }

  state.commentThreadsDictionary[elementId] = [
    ...(state.commentThreadsDictionary[elementId] ?? []),
    newConversation,
  ];
};
