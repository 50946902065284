import React from "react";
import { AlertProps } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectSuccessText } from "src/store/selectors/snackbar-text.selectors";
import { SuccessSnackbar } from "./SuccessSnackbar";
import { setSuccessText } from "src/store/slices/snackbar-text.slice";

export interface ErrorSnackbarProps {
  title?: string;
  permanent?: boolean;
  duration?: number;
  severity?: AlertProps["severity"];
}

export const StateSuccessSnackbar = () => {
  const successMessage = useAppSelector(selectSuccessText);
  const dispatch = useAppDispatch();

  const handleSnackbarClose = React.useCallback(() => {
    dispatch(setSuccessText(null));
  }, [dispatch]);

  return (
    <SuccessSnackbar onClose={handleSnackbarClose} message={successMessage} />
  );
};
