import { CloseIcon } from "@chakra-ui/icons";
import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { BeenhereOutlined } from "@mui/icons-material";
import React from "react";
import { useElementContext } from "src/hooks/useElementContext";
import { AttestationInstance } from "src/types";

export const AttestationDate = ({
  index,
  attestation,
  elementIsEditable,
  updateAttestation,
}: {
  index: number;
  attestation: AttestationInstance;
  updateAttestation: (
    index: number,
    field: string,
    newValue: AttestationInstance[keyof AttestationInstance]
  ) => void;
  elementIsEditable: boolean;
}) => {
  const { element } = useElementContext();
  const [attestationDate, setAttestationDate] = React.useState(
    attestation.date ?? ""
  );
  const date = new Date();

  React.useEffect(() => {
    if (attestation.date) {
      setAttestationDate(attestation.date);
    }
  }, [attestation.date]);

  const updateDate = () => {
    if (!elementIsEditable) return;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    setAttestationDate(formattedDate);
    updateAttestation(index, "date", formattedDate);
  };

  const clearDate = () => {
    setAttestationDate("");
    updateAttestation(index, "date", "");
  };

  return (
    <VStack mb={2}>
      <InputGroup>
        <InputLeftElement>
          <Icon as={BeenhereOutlined} color={"red.600"} />
        </InputLeftElement>
        <Input
          data-test-id={`attestation-date-input-${element?.pathIndex.join("-")}-${index}`}
          placeholder="Click to date"
          readOnly={true}
          onClick={updateDate}
          variant="flushed"
          style={{ cursor: "pointer" }}
          value={attestationDate}
        />
        <InputRightElement onClick={clearDate}>
          {attestationDate ? <CloseIcon color={"gray.300"} /> : null}
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
};
