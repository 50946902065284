import React from "react";
import { Flex, VStack, RadioGroup, Radio } from "@chakra-ui/react";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { FormElementBuildViewProps } from "src/types/form.types";
import { PreviewHeader } from "./PreviewHeader";
import { Typography } from "@mui/material";

export const SingleSelectPreview = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));
  const dataTestIdPathIndex = React.useMemo(
    () => element?.pathIndex.join("-"),
    [element?.pathIndex]
  );

  if (element && element.type !== "singleSelect") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <Flex
      data-test-id={`single-select-preview-${dataTestIdPathIndex}`}
      w={"100%"}
      direction="column"
    >
      <div style={{ width: "100%" }}>
        <PreviewHeader
          rules={element.rules}
          title={element.content.title}
          titleNumber={element.titleNumber ?? ""}
          adjudicatorNotes={element.content.adjudicatorNotes}
          surveyTakerNotes={element.content.surveyTakerNotes}
          audience={element.content.audience}
          questionPlaceholder="Enter the question"
          question={element.content.question}
        />
      </div>
      <RadioGroup
        _disabled={{ opacity: "100%" }}
        isDisabled={true}
        isFocusable={false}
      >
        <VStack gap={1} alignItems={"flex-start"} justifyContent={"flex-start"}>
          {element.content.items
            .filter((item) => item.allowed)
            .map((item, i) => (
              <Radio
                _disabled={{ opacity: "100%" }}
                isFocusable={false}
                colorScheme="brightblue"
                key={i}
                value={item.label}
                alignItems={"flex-start"}
              >
                <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                  {item.label}
                </Typography>
              </Radio>
            ))}
          {element.content.other.allowed && (
            <Radio
              _disabled={{ opacity: "100%" }}
              isFocusable={false}
              colorScheme="brightblue"
              value={element.content.other.label}
              alignItems={"flex-start"}
            >
              <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                {element.content.other.label}
              </Typography>
            </Radio>
          )}
          {element.content.none.allowed && (
            <Radio
              _disabled={{ opacity: "100%" }}
              isFocusable={false}
              colorScheme="brightblue"
              value={element.content.none.label}
              alignItems={"flex-start"}
            >
              <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                {element.content.none.label}
              </Typography>
            </Radio>
          )}
        </VStack>
      </RadioGroup>
    </Flex>
  );
};
