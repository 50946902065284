import React from "react";
import { Flex, Collapse, Icon, Divider } from "@chakra-ui/react";
import { Colors } from "../theme/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DocumentSectionToolbar } from "./DocumentSectionToolbar";
import SelectStaticComponent from "./SelectStaticComponent";
import ElementConversations, {
  IAddNewComment,
  IAddNewConversation,
  IResolveConversation,
} from "./ElementConversations";
import ForumOutlined from "@mui/icons-material/ForumOutlined";
import { NewConversationButton } from "./NewConversationButton";
import { CommentThread } from "../types";
import { useAppSelector, useAppDispatch } from "src/store";
import { selectSelectedElement } from "src/store/selectors/selected-element.selectors";
import { ClickAwayListener } from "@mui/material";
import { filterCommentThreadsDictionary } from "src/util/comments.utils";
import { selectPrimaryCommentFilter } from "src/store/selectors/comments.selectors";
import { selectSecondaryCommentFilter } from "src/store/selectors/comments.selectors";
import { selectCommentThreadsDictionary } from "src/store/selectors/comments.selectors";
import { newConversation } from "src/store/slices/comments.slice";
import {
  resolveConversation,
  updateConversation,
} from "src/store/slices/comments.slice";

const COLLAPSE_ICON_Y = -25;
export const COMMENT_ICON_X = "810px";
export const ELEMENT_LEFT_PADDING = 5;
export const BORDER_WIDTH = 2;

export const DocumentSectionWrapper = ({
  elementId,
  readOnly = false,
  children,
  userRoleOnDocument,
  isSection,
}: {
  elementId: string;
  readOnly?: boolean;
  children: React.ReactElement;
  userRoleOnDocument: string;
  isSection: boolean;
}) => {
  const [addSectionVisible, setAddSectionVisible] = React.useState(false);
  const [filteredCommentThreads, setFilteredCommentThreads] =
    React.useState<CommentThread[]>();
  const [sectionOpen, setSectionOpen] = React.useState(true);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const commentThreadsRef = React.useRef<CommentThread[]>([]);
  const selectedElementId = useAppSelector(selectSelectedElement);
  const primaryCommentFilter = useAppSelector(selectPrimaryCommentFilter);
  const secondaryCommentFilter = useAppSelector(selectSecondaryCommentFilter);
  const globalUserInfo = useAppSelector((state) => state.user.userContext);
  const commentThreadsDictionary = useAppSelector(
    selectCommentThreadsDictionary
  );
  const dispatch = useAppDispatch();

  const mentionableMembers = useAppSelector(
    (state) => state.organization.mentionableMembers
  );
  const divRef = React.useRef(null);
  const isSelected = selectedElementId === elementId;

  React.useEffect(() => {
    if (commentThreadsDictionary && globalUserInfo) {
      const filteredDictionary = filterCommentThreadsDictionary(
        commentThreadsDictionary,
        secondaryCommentFilter,
        primaryCommentFilter,
        userRoleOnDocument,
        globalUserInfo
      );
      setFilteredCommentThreads(filteredDictionary[elementId]);
      commentThreadsRef.current = filteredDictionary[elementId] ?? [];
    }
  }, [
    commentThreadsDictionary,
    primaryCommentFilter,
    secondaryCommentFilter,
    elementId,
    globalUserInfo,
    userRoleOnDocument,
  ]);

  React.useEffect(() => {
    const handleMouseEnter = (e: MouseEvent) => {
      const closestParent = (e.target as HTMLElement).closest(
        ".element-wrapper"
      );

      setIsHovered(closestParent === divRef.current);
      e.stopPropagation();
    };

    document.addEventListener("mouseover", handleMouseEnter);

    return () => {
      document.removeEventListener("mouseover", handleMouseEnter);
    };
  }, []);

  const handleClickAway = React.useCallback(() => {
    if (!addSectionVisible) {
      return;
    }
    setAddSectionVisible(false);
  }, [addSectionVisible]);

  const resolveConversationFunction = React.useCallback(
    ({ commentThread }: { commentThread: CommentThread }) => {
      dispatch(
        resolveConversation({
          commentThread,
          commentThreads: commentThreadsRef.current,
          resolved: !commentThread.resolved,
          elementId,
        })
      );
    },
    [dispatch, elementId]
  );

  const addNewComment = React.useCallback(
    ({ commentThread, newComment, assignedTo, mentioned }: IAddNewComment) => {
      dispatch(
        updateConversation({
          elementId: elementId,
          commentThreads: commentThreadsRef.current,
          commentThread,
          comment: newComment,
          resolved: false,
          globalUserInfo,
          mentionableMembers,
          assignedTo,
          mentioned,
        })
      );
    },
    [dispatch, elementId, globalUserInfo, mentionableMembers]
  );

  const addNewConversation = React.useCallback(
    ({ newCommentText, type, assignedTo, mentioned }: IAddNewConversation) => {
      dispatch(
        newConversation({
          elementId: elementId,
          conversationType: type,
          mentionableMembers,
          commentText: newCommentText,
          globalUserInfo,
          commentThreads: commentThreadsRef.current,
          assignedTo,
          mentioned,
        })
      );
    },
    [dispatch, elementId, globalUserInfo, mentionableMembers]
  );

  const handleToggleElementCollapse = React.useCallback(() => {
    setSectionOpen((prevSectionOpen) => {
      return !prevSectionOpen;
    });
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        id={elementId}
        ref={divRef}
        className="element-wrapper"
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          height: "fit-content",
        }}
      >
        {filteredCommentThreads && filteredCommentThreads.length !== 0 ? (
          <>
            <Icon
              data-test-id="existing-comment-icon"
              style={{
                zIndex: 0,
                position: "absolute",
                left: COMMENT_ICON_X,
                padding: 2,
              }}
              as={ForumOutlined}
              boxSize={6}
              color={isSelected ? "brightblue.500" : Colors.gray[500]}
            />
            {isSelected ? (
              <div
                style={{
                  position: "absolute",
                  left: 850,
                  display: isSelected ? "flex" : "none",
                }}
              >
                <ElementConversations
                  isSelected={isSelected}
                  filteredCommentThreads={filteredCommentThreads}
                  addNewConversation={({
                    newCommentText,
                    type,
                    assignedTo,
                    mentioned,
                  }: IAddNewConversation) =>
                    addNewConversation({
                      newCommentText,
                      type,
                      assignedTo,
                      mentioned,
                    })
                  }
                  resolveConversation={(x: IResolveConversation) =>
                    resolveConversationFunction({
                      commentThread: x.commentThread,
                    })
                  }
                  addNewComment={addNewComment}
                  userRoleOnDocument={userRoleOnDocument}
                />
              </div>
            ) : null}
          </>
        ) : isSelected ? (
          <div
            style={{
              position: "absolute",
              left: COMMENT_ICON_X,
              ...(isSelected
                ? styles.selectedIcon
                : isHovered
                  ? styles.hoveredIcon
                  : styles.disabledIcon),
            }}
          >
            <NewConversationButton
              addNewConversation={({
                newCommentText,
                type,
                assignedTo,
                mentioned,
              }: IAddNewConversation) =>
                addNewConversation({
                  newCommentText,
                  type,
                  assignedTo,
                  mentioned,
                })
              }
              userRoleOnDocument={userRoleOnDocument}
              isSelected={isSelected}
              isHovered={isHovered}
              elementId={elementId}
            />
          </div>
        ) : null}
        <Flex
          direction={"column"}
          style={{
            justifyContent: "flex-start",
            height: "fit-content",
            paddingLeft: ELEMENT_LEFT_PADDING,
            ...(isSelected
              ? styles.border
              : isHovered
                ? styles.hoveredBorder
                : styles.disabledBorder),
          }}
        >
          <Divider
            style={{
              border: isSelected
                ? `1px solid ${Colors.brightblue[500]}`
                : "0px solid transparent",
              opacity: 1,
              transition: "border 0.3s ease-in-out",
              left: readOnly ? COLLAPSE_ICON_Y : COLLAPSE_ICON_Y * 2,
              position: "absolute",
              marginTop: -2,
              width: "130px",
            }}
          />

          {readOnly ? null : isSelected ? (
            <DocumentSectionToolbar
              id={elementId}
              setAddSectionVisible={setAddSectionVisible}
              addSectionVisible={addSectionVisible}
              isSelected={isSelected}
            />
          ) : null}

          <div
            style={{
              display: "flex",
              position: "absolute",
              left: COLLAPSE_ICON_Y,
              marginTop: -7,
              ...(isSelected
                ? styles.selectedIconBox
                : isHovered
                  ? styles.hoveredIconBox
                  : styles.disabledIconBox),
              ...(isSelected ? { zIndex: 1000 } : { zIndex: "initial" }),
            }}
          >
            <Icon
              onClick={handleToggleElementCollapse}
              style={{
                cursor: "pointer",
                ...(isSelected
                  ? styles.selectedIcon
                  : isHovered
                    ? styles.hoveredIcon
                    : styles.disabledIcon),
              }}
              color={isSelected ? Colors.brightblue[500] : Colors.gray[200]}
              as={sectionOpen ? RemoveIcon : AddIcon}
              boxSize={4}
            />
          </div>

          <Collapse
            style={{
              width: "100%",
              height: "fit-content",
              paddingBottom: 6,
              paddingTop: 6,
              overflow: sectionOpen ? "initial" : "hidden",
            }}
            startingHeight={35}
            in={sectionOpen}
            animateOpacity
          >
            {children}
          </Collapse>
        </Flex>
        {addSectionVisible ? (
          <Flex
            style={{
              display: addSectionVisible ? "block" : "none",
              padding: 15,
            }}
          >
            <Collapse in={addSectionVisible} animateOpacity>
              <SelectStaticComponent
                elementId={elementId}
                isSection={isSection}
                onClose={() => setAddSectionVisible(false)}
              />
            </Collapse>
          </Flex>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

const styles = {
  border: {
    borderTop: `${BORDER_WIDTH}px solid ${Colors.brightblue[500]}`,
    borderLeft: `${BORDER_WIDTH}px solid ${Colors.brightblue[500]}`,
    borderBottom: `${BORDER_WIDTH}px solid ${Colors.brightblue[500]}`,
    borderRight: "${BORDER_WIDTH}px solid transparent",
    transition: `border 0.3s ease-in-out`,
  },
  hoveredBorder: {
    borderTop: `${BORDER_WIDTH}px solid ${Colors.gray[200]}`,
    borderLeft: `${BORDER_WIDTH}px solid ${Colors.gray[200]}`,
    borderBottom: `${BORDER_WIDTH}px solid ${Colors.gray[200]}`,
    borderRight: `${BORDER_WIDTH}px solid transparent`,
    transition: `border 0.3s ease-in-out 1s`,
  },
  disabledBorder: {
    transition: `border 0.3s ease-in-out`,
    border: `${BORDER_WIDTH}px solid transparent`,
  },
  disabledIconBox: {
    border: "transparent",
    backgroundColor: "transparent",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out, background-color 0.3s ease-in-out",
  },
  hoveredIconBox: {
    border: `${BORDER_WIDTH}px solid ${Colors.gray[200]}`,
    backgroundColor: "white",
    marginTop: -7,
    opacity: 1,
    transition:
      "opacity 0.3s ease-in-out 1s, background-color 0.3s ease-in-out 1s",
  },
  selectedIconBox: {
    border: `${BORDER_WIDTH}px solid ${Colors.brightblue[500]}`,
    backgroundColor: "white",
    opacity: 1,
  },
  selectedIcon: {
    opacity: 1,
  },
  hoveredIcon: {
    opacity: 1,
    transition: "border 0.3s ease-in-out 1s, opacity 0.3s ease-in-out 1s",
  },
  disabledIcon: {
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
};
